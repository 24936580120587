@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.close-dropdown-container-for-mobile {
    // position: relative;
    // top: 50px;
    // float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 10px;
    margin: 40px 0px 40px auto;
    background: var(--bodyback-grey);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

// for small mobiles
@media screen and (max-width: 374.9px) {
    .parent-container-short,
    .userpanel-container {
        width: 100% !important;
    }
    .modal-menu-for-mobile {
        width: 70% !important;
    }

    .site-main-filtre-out-container {
        .site-main-filter-single-search-container {
            padding: 35px 10px !important;
        }
    }

    .card {
        .slide-img-box-for-postcard {
            height: 350px !important;
        }
    }

    .userpanel-container {
        .userpanel-screen-main-container {
            .generate-qr-container {
                width: 100%;
                .qr-collar-order-buttons-container {
                    flex-direction: column;
                    button,
                    a {
                        width: 80%;
                        padding: 16px;
                    }
                }
                .purchased-collars {
                    .purchased-collars-cards-container {
                        grid-template-columns: repeat(1, 1fr);
                        // padding: 0 15px;
                        .modal-background {
                            background: rgba(0, 0, 0, 0.9);
                            .qr-collar-buttons-instruction-container {
                                position: absolute;
                                top: 0;
                                left: 0;
                                justify-content: flex-start;
                                width: 100%;
                                height: 100%;
                                transform: none;
                                -webkit-transform: none;
                                -moz-transform: none;
                                -ms-transform: none;
                                -o-transform: none;
                            }
                        }
                        .purchased-collars-card {
                            flex-direction: column;
                            justify-content: center;
                            // height: 100%;

                            .pet-img-and-buttons {
                                align-items: center;
                                width: 100%;

                                .purchased-collars-card-pet-name {
                                    padding: 10px 0;
                                }
                                .img-box {
                                    width: 130px;
                                    height: 130px;
                                    min-width: 130px;
                                    min-height: 130px !important;
                                }
                                .edit-delete-container {
                                    padding: 7px 10px;
                                    .hide-instruction-btn {
                                        display: none !important;
                                    }
                                    button {
                                        opacity: 1;
                                    }
                                    .update-owner-info {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .pet-info-outside-container {
                        position: relative !important;
                        // margin-top: 5px;
                        .pet-info-top-buttons-container {
                            display: flex !important;

                            .close-update-container {
                                display: none !important;
                            }
                        }
                        .pet-info-container {
                            .pet-info-container-title {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 767.9px) {
    .userpanel-container {
        .userpanel-screen-main-container {
            .generate-qr-container {
                width: 100%;
                .qr-collar-order-buttons-container {
                    flex-direction: column;
                    button,
                    a {
                        width: 80%;
                        padding: 16px;
                    }
                }
                .purchased-collars {
                    .purchased-collars-cards-container {
                        grid-template-columns: repeat(1, 1fr);
                        // padding: 0 15px;
                        .modal-background {
                            background: rgba(0, 0, 0, 0.9);
                            .qr-collar-buttons-instruction-container {
                                position: absolute;
                                top: 0;
                                left: 0;
                                justify-content: flex-start;
                                width: 100%;
                                height: 100%;
                                transform: none;
                                -webkit-transform: none;
                                -moz-transform: none;
                                -ms-transform: none;
                                -o-transform: none;
                            }
                        }
                        .purchased-collars-card {
                            flex-direction: column;
                            justify-content: center;
                            // height: 100%;

                            .pet-img-and-buttons {
                                align-items: center;
                                width: 100%;

                                .purchased-collars-card-pet-name {
                                    padding: 10px 0;
                                }
                                .img-box {
                                    width: 170px;
                                    height: 170px;
                                    min-width: 170px;
                                    min-height: 170px !important;
                                }
                                .edit-delete-container {
                                    padding: 7px 10px;
                                    .hide-instruction-btn {
                                        display: none !important;
                                    }
                                    button {
                                        opacity: 1;
                                    }
                                    .update-owner-info {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .pet-info-outside-container {
                        position: relative !important;
                        // margin-top: 5px;
                        .pet-info-top-buttons-container {
                            display: flex !important;

                            .close-update-container {
                                display: none !important;
                            }
                        }
                        .pet-info-container {
                            .pet-info-container-title {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// for normal mobiles
@media screen and (max-width: 767.9px) {
    body {
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }
    * {
        padding: 0;
        margin: 0;
    }
    .parent-container-short,
    .userpanel-container {
        width: 100%;
        // width: calc(100% - 10px) !important;
        // padding: 0 14px;
    }
    .full-site-parent-container,
    .footer-parent-container {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }
    .breeds-dropdown-size {
        width: 100% !important;
        ul.options {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            scroll-behavior: contain !important;
        }
    }
    .flex {
        display: block !important;
    }
    .city-dropdown-size {
        width: 100% !important;
        ul.options {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            scroll-behavior: contain !important;
        }
    }
    .modal-box-for-mobile {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 100vh;
        background: rgba(23, 23, 23, 0.8) !important;
    }
    .modal-box-for-mobile.active {
        display: block;
    }
    .hr-for-mobile-menu {
        width: 40%;
        margin: 10px auto;
        background: transparent;
        border: 1px solid transparent;
        border-radius: $third-border-radius;
        -webkit-border-radius: $third-border-radius;
        -moz-border-radius: $third-border-radius;
        -ms-border-radius: $third-border-radius;
        -o-border-radius: $third-border-radius;
    }
    .modal-menu-for-mobile {
        position: relative;
        // top: 50%;
        width: 100% !important;
        height: 100vh !important;
        margin: auto;
        padding: 30px;
        //margin-left: auto;
        text-align: left;
        // padding-left: 30px;
        // padding: 60px 30px;
        // clear: both;
        background: var(--bodyback);
        // border-radius: 30px;
        // -webkit-border-radius: 30px;
        // -moz-border-radius: 30px;
        // -ms-border-radius: 30px;
        // -o-border-radius: 30px;

        animation: customAni 0.2s ease 0s normal none;
        -webkit-animation: customAni 0.2s ease 0s normal none;

        // transform: translateY(-50%);
        // -webkit-transform: translateY(-50%);
        // -moz-transform: translateY(-50%);
        // -ms-transform: translateY(-50%);
        // -o-transform: translateY(-50%);

        p {
            display: none;
            width: fit-content;
            color: black;
            font-size: 1.5rem;
            padding: 10px 7px;
            padding-top: 0;
            margin-bottom: 10px;
            margin-left: 12px;
            border: none;
            border-bottom: 1px solid rgba(55, 55, 55, 1);
        }

        .modal-menu-for-mobile-inside-container {
            position: relative;
            // background: var(--bodyback-grey);
            // top: 50%;
            // transform: translateY(-50%);
            // -webkit-transform: translateY(-50%);
            // -moz-transform: translateY(-50%);
            // -ms-transform: translateY(-50%);
            // -o-transform: translateY(-50%);

            a {
                width: 100%;
                color: black;
                padding: 20px;
                margin: 10px auto;
                font-size: 0.9rem;
                font-weight: 700;
                display: block;
                text-decoration: none;
                text-align: center;
                background: var(--bodyback-grey);
                border-radius: $primary-border-radius;
                -webkit-border-radius: $primary-border-radius;
                -moz-border-radius: $primary-border-radius;
                -ms-border-radius: $primary-border-radius;
                -o-border-radius: $primary-border-radius;
            }
        }
    }
    .close-dropdown-container-for-mobile {
        display: flex;
    }

    .close-modal-icon-box {
        position: absolute;
        top: 30px;
        right: 30px;
        display: none; // inline-block;
        width: 50px;
        height: 50px;
        //text-align: center;
        padding: 0 !important;
        border: none;
        background: var(--bodyback);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        .close_mobilemenu {
            position: absolute;
            top: 50%;
            left: 50%;
            // display: inline-block;
            // height: 28px;
            color: rgba(55, 55, 55, 1);
            text-align: center;
            font-size: 2rem;
            margin-top: -3px;
            padding: 0 !important;
            border: none;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
        }
        .close_mobilemenu:hover,
        .close_mobilemenu:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }
    /****END MODAL MENU FOR MOBILE CONTAINER****/
    .header-top-container {
        // display: flex !important;
        border-bottom: 1px solid var(--background);
        .header-top-inside-container {
            .header-left-container {
                gap: 15px;
                .social-icons {
                    gap: 5px;
                }
                .top-aboutus {
                    display: none;
                }
                .top-order-collar {
                    display: none;
                }
            }
        }
    }
    .header-middle-parent-container {
        background: var(--bodyback) !important;

        .header-middle-inside-container {
            width: calc(100% - 30px);
            // padding: 20px;
            background: var(--bodyback);
            .logo-box {
                a {
                    img {
                        height: 22px;
                        object-fit: contain;
                        margin: 0;
                    }
                }
            }
            /****END LOGO BOX*****/
            .header-middle-short-menu {
                display: none;
            }
            .header-search-container {
                display: none;
            }
            .header-right-container {
                gap: 21px;
                // background: red;
                .menu-for-mobile {
                    display: flex !important;
                    width: 30px;
                    height: 30px;
                    padding: 0 !important;
                    margin: 0 !important;
                    &:hover {
                        border-color: transparent;
                    }
                    svg {
                        // width: 25px;
                        height: 26px;
                        max-height: 26px;
                    }
                }
                .login-signup-container {
                    button {
                        width: 30px;
                        height: 30px;
                        padding: 0;
                        background: var(--bodyback-grey);
                        border-color: transparent;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        &:hover {
                            border-color: transparent;
                        }

                        .user-svg-container {
                            width: 100% !important;
                            height: 100% !important;
                            max-width: 100% !important;
                            max-height: 100% !important;

                            svg {
                                padding: 4px !important;
                            }
                        }
                        // svg {
                        //     width: 26px;
                        //     height: 26px;
                        //     max-height: 26px;
                        //     padding: 0 !important;
                        //     background: transparent !important;
                        //     path {
                        //         stroke: black;
                        //         fill: transparent;
                        //     }
                        // }
                        p {
                            display: none;
                        }
                    }
                }
                .login-signup-container.active {
                    button {
                        border-color: transparent;
                    }
                }
                .user-head-container {
                    position: static !important;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    button {
                        position: static !important;
                        padding: 0;
                        background: transparent;
                        border-color: transparent;
                        &:hover {
                            border-color: transparent;
                        }
                        svg {
                            width: 25px;
                            max-height: 25px;
                        }
                        .user-profile-first-letters {
                            display: flex !important;
                            justify-content: center !important;
                            align-items: center !important;
                            color: black;
                            width: 30px;
                            height: 30px;
                            padding: 0 !important;
                            background: $sec-design-color;

                            p {
                                font-size: 1rem;
                            }
                        }
                        a.user-fullname {
                            display: none;
                        }
                    }
                    .user-dropdown-container {
                        //position: absolute;
                        top: 0;
                        //left: 50%;
                        //flex-direction: column;
                        //justify-content: center;
                        //align-items: center;
                        width: 100% !important;
                        height: 100vh !important;
                        text-align: center;
                        margin-top: 0;
                        //margin-top: 20% !important;
                        //padding-top: 10px;
                        //height: 100vh !important;
                        padding: 10px;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;

                        // transform: translate(-50%);
                        // -webkit-transform: translate(-50%);
                        // -moz-transform: translate(-50%);
                        // -ms-transform: translate(-50%);
                        // -o-transform: translate(-50%);

                        .close-dropdown-container-for-mobile {
                            display: flex;
                        }

                        .user-info-mini-container {
                            justify-content: center !important;
                            gap: 10px;
                            width: 90%;
                            padding: 15px 0;
                            margin: 20px auto 20px auto;
                            background: var(--bodyback-grey);
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;

                            img {
                                width: 3rem;
                                height: 3rem;
                            }
                        }

                        hr {
                            display: none;
                            margin: 10px auto;
                        }

                        ul li a {
                            font-size: 0.9rem !important;
                            // font-weight: 700 !important;
                            padding: 15px;
                        }
                    }
                }
                .user-head-container.active {
                    button {
                        border-color: transparent;
                        background: transparent !important;
                    }

                    // .user-dropdown-container {
                    //     display: flex;
                    // }
                }
                .language-and-darkmode {
                    // gap: 21px;
                    padding: 0;
                    .lang-switcher-container {
                        position: static !important;
                        // width: 30px;
                        // height: 30px;
                        // max-height: 30px;
                        // background: green;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        button {
                            position: static !important;
                            width: 30px;
                            height: 30px;
                            max-height: 30px;
                            padding: 3.5px;
                            background: var(--bodyback-grey);
                            border-color: transparent;
                            border-radius: inherit;
                            -webkit-border-radius: inherit;
                            -moz-border-radius: inherit;
                            -ms-border-radius: inherit;
                            -o-border-radius: inherit;
                            &:hover {
                                border-color: transparent;
                            }
                            svg.earth {
                                display: flex;
                                height: 20px;
                                max-height: 20px;
                            }
                            img {
                                display: none;
                                height: 22px;
                                max-height: 22px;
                            }
                            span {
                                display: none;
                            }
                        }
                    }
                    .lang-list-container {
                        top: 0;
                        //top: 50%;
                        // left: 50%;
                        width: 100% !important;
                        height: 100vh !important;
                        // margin-top: 30% !important;
                        //padding-top: 10px;
                        //height: 100vh !important;
                        padding: 30px;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;
                        // transform: translate(-50%);
                        // -webkit-transform: translate(-50%);
                        // -moz-transform: translate(-50%);
                        // -ms-transform: translate(-50%);
                        // -o-transform: translate(-50%);

                        .close-dropdown-container-for-mobile {
                            display: flex !important;

                            svg path {
                                stroke: black !important;
                            }
                        }

                        ul {
                            width: 95%;
                            margin: 65px auto;
                            h3 {
                                display: flex !important;
                                margin: 15px auto;
                            }
                            li {
                                padding: 20px !important;
                                margin: 10px auto;
                                background: var(--bodyback-grey);
                                border-radius: $primary-border-radius;
                                -webkit-border-radius: $primary-border-radius;
                                -moz-border-radius: $primary-border-radius;
                                -ms-border-radius: $primary-border-radius;
                                -o-border-radius: $primary-border-radius;
                            }
                        }

                        .current-currency-for-mobile {
                            display: block !important;

                            li {
                                input {
                                    display: none;
                                }
                            }
                        }
                    }
                    .lang-switcher-container.active {
                        button {
                            border-color: transparent;
                            background: transparent !important;
                        }
                    }
                    .dark-mode-btn {
                        padding: 0;
                        background: transparent;
                        border-color: transparent;
                        &:hover {
                            border-color: transparent;
                        }
                        svg {
                            height: 25px;
                            max-height: 25px;
                        }
                    }
                    .header-currency {
                        display: none;
                        width: 30px;
                        height: 30px;
                        background: var(--bodyback-grey);
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;

                        .currency-switch {
                            padding: 0 !important;
                            svg {
                                width: 19px;
                                height: 19px;
                            }
                        }
                    }
                }
            }
        }
    }
    .head-nav {
        display: none;
    }
    .qr-tags-swiper {
        // margin-top: 20px;
        height: fit-content !important;
        max-height: fit-content !important;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
            right: 0;
        }
        .swiper-button-prev {
            left: 0;
        }
    }
    .qr-tags-container {
        height: 100% !important;
        margin-top: 0;
        padding: 30px 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        // .overlay-2 {
        //     .text-for-qr-tags-container {
        //         .right-face-img {
        //             width: 100% !important;
        //             height: 300px !important;
        //             object-fit: contain !important;
        //         }
        //     }
        // }
        // .overlay-3 {
        //     .text-for-qr-tags-container {
        //         .right-face-img {
        //             width: 100% !important;
        //             height: 300px !important;
        //             object-fit: contain !important;
        //         }
        //     }
        // }
        .qr-tags-container-overlay {
            width: calc(100% - 20px);
            .text-for-qr-tags-container {
                flex-direction: column;
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: center !important;
                gap: 10px;
                padding: 0;
                // background-color: rgba(0, 0, 0, 0.6);
                .qr-tag-main-text {
                    width: 100%;
                    max-width: 90%;
                    text-align: center;
                    gap: 10px;
                    // padding: 0 10px;
                    p {
                        margin-top: 15px;
                        text-align: center;
                        font-size: 1rem;
                        // color: var(--bodyback) !important;
                        .priority {
                            font-size: 1rem;
                        }
                    }
                    span {
                        // color: var(--bodyback);
                    }
                    ul {
                        li {
                            white-space: nowrap !important;
                            line-height: 3em;
                        }
                    }
                    a {
                        width: 100%;
                        font-size: 0.65rem;
                        margin-top: 10px;
                        margin: 10px auto 0 auto;
                    }
                    .pethub_app_text {
                        font-size: 0.75rem;
                    }
                    .app-download {
                        justify-content: center;
                    }
                    .join-us {
                        margin: auto;
                        margin-top: 30px;
                    }
                }
                .right-face-img {
                    width: 70%;
                    max-width: 70%;
                }
            }
        }
    }
    .custom-qr-tag-by-user-parent-container {
        width: 100%;
        gap: 0;
        margin: 30px auto 0 auto !important;
        background: #f3e5dc;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        .custom-qr-tag-by-user {
            .orera {
                left: -1px;
            }
        }
        .custom-qr-tag-by-user-text {
            max-width: 100%;
            text-align: center;
            margin-top: 10px;
            padding: 0 10px;

            p {
                font-size: 1.6rem;
            }

            h1 {
                font-size: 1.5rem !important;
            }

            a {
                margin-bottom: 40px;
            }
        }
    }
    .members-and-tags-counter-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .qr-tag-list-container {
        width: calc(100% - 20px);
        margin: 30px auto !important;
        .qr-tag-list-inside-container {
            .prev,
            .next {
                display: none !important;
            }
        }
    }
    .qr-tag-card {
        max-height: 350px;
        .qr-tag-card-imgbox {
            height: 350px;
            img {
                object-fit: cover;
            }
        }
    }
    .qr-and-gps-tags {
        .qr-and-gps-tags-inside-container {
            grid-template-columns: repeat(1, 1fr);
            .tag-container {
                width: 90%;
                margin: auto;
            }
        }
    }
    .tags-card-container-for-homepage {
        .tags-card-inside-container-for-homepage {
            grid-template-columns: repeat(1, 1fr);
            .tag-card-for-home-page {
                width: 90%;
                height: auto;
                margin: auto;
            }
        }
    }
    .qr-and-gps-tags {
        .qr-and-gps-tags-inside-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .site-main-filtre-out-container {
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto 30px auto;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        .site-main-filter-single-search-container {
            width: 100%;
            // padding: 35px 30px;
            // padding: 14px 21px;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            &::-webkit-scrollbar {
                width: 3.5px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent !important;
            }
            &::-webkit-scrollbar-thumb {
                background-color: transparent !important;
            }
            .pets-button-in-filter-container {
                justify-content: center;
                flex-wrap: wrap;
                column-gap: 0;
                overflow: auto;
                margin: 7px 0;
                .pets-button-container {
                    .pets-button {
                        width: 100%;
                        padding: 15px 25px;
                        margin-bottom: 5px;
                    }
                }
                .pets-documents {
                    justify-content: flex-start;
                    gap: 7px;
                    .wrapper {
                        .select-menu-dropdown {
                            width: 100%;
                            ul.options {
                                justify-content: flex-start;
                                gap: 0;
                                padding: 0;
                                label {
                                    width: auto;
                                    padding: 15px 20px;
                                }
                            }
                        }
                    }
                    .currency {
                        .currency-switch {
                            svg {
                                height: 14px;
                            }
                        }
                    }
                }
                .clear-filters {
                    display: none;
                    // padding: 12px;
                    color: white;
                    &:hover {
                        background-color: $sec-design-color;
                    }
                    svg {
                        max-height: 30px;
                        path {
                            stroke: black;
                        }
                    }
                }
            }
            .site-main-filtre {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 0;
                margin: 0;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border: none;
                .wrapper {
                    width: 100%;
                    margin: 10px auto;
                    .select-button {
                        // padding: 15px;
                        background-color: $fourth-color;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        &:hover {
                            background-color: $fourth-color;
                        }
                        div {
                            color: $primary-color;
                            padding-left: 7px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: 90%;
                        }
                        svg {
                            right: 10%;
                        }
                    }
                    .select-menu-dropdown {
                        width: 100%;
                        margin: 7px 0;
                        // border-radius: $third-border-radius;
                        // -webkit-border-radius: $third-border-radius;
                        // -moz-border-radius: $third-border-radius;
                        // -ms-border-radius: $third-border-radius;
                        // -o-border-radius: $third-border-radius;

                        .select-search {
                            input {
                                padding: 20px 60px;
                                background-position: 30px center !important;
                            }
                        }

                        ul.options {
                            label {
                                padding: 20px 10px;
                            }
                        }
                    }
                }
                .filter-price {
                    display: block;
                    margin: 10px auto 20px auto;
                    // padding: 0;
                    width: 100%;
                    overflow: hidden;
                    background: white;

                    input[type="text"] {
                        width: 50%;
                        margin: 0;
                        // padding: 15px 20px !important;
                    }
                    .currency {
                        display: none;
                    }
                }

                .clear-filters-for-mobile {
                    display: flex;
                    margin: 20px auto;
                }
            }
            .contact-details-outside-container {
                display: none !important;
            }
        }
    }
    .filter-left-version {
        width: 100%;
        margin-bottom: 25px !important;
        padding: 0;
        .filter-left-version-inside-container {
            .search-box {
                margin: 0 !important;
            }
            .pets-button-in-filter-container {
                width: 100%;
                .pets-button-container-for-left-version {
                    width: 100%;
                    .pets-button {
                        padding: 20px;
                    }
                }
            }
            .site-main-filtre {
                .wrapper {
                    .select-button {
                    }
                    .select-menu-dropdown {
                        display: none;
                    }
                    .select-button.active {
                        .select-menu-dropdown {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
    .main-container {
        width: calc(100% - 20px);
    }
    .card-2-container {
        width: 100% !important;
        .inside-container {
            width: 100% !important;
            padding: 0;
            .card-2 {
                flex-direction: column !important;
                height: auto !important;
                .slide-img-box-for-card2 {
                    width: 100% !important;
                    height: 300px !important;
                }
                .card-2-info-container {
                    width: 100% !important;
                    row-gap: 30px;
                    padding: 10px !important;
                    .card-2-breed-city {
                        margin: 0 !important;
                    }
                    .card-2-age-gender-doc {
                        flex-wrap: wrap !important;
                        column-gap: 5px !important;
                        row-gap: 15px !important;
                        padding: 0 !important;
                    }
                    .card-2-price-rate {
                    }
                }
            }
        }
    }
    .card {
        gap: 21px;
        width: 100%;
        margin: auto;
        // padding: 21px;
        background: var(--bodyback);

        .slide-img-box-for-postcard {
            height: 375px;
            // min-height: 400px;
            // max-height: 400px;
            border-radius: inherit;
            -webkit-border-radius: inherit;
            -moz-border-radius: inherit;
            -ms-border-radius: inherit;
            -o-border-radius: inherit;
        }

        .overlay-breed-and-save-container {
            bottom: 15px;

            .overlay-breed-container {
                padding: 10px;
            }

            .save-button-container {
                padding: 10px;

                .save-button {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        .overlay-card-info-container {
            top: 20px;

            .price-doc-vacc-container {
                gap: 15px;
            }

            .overlay-doc-vacc-container {
                span {
                    padding: 10px;

                    svg {
                        height: 20px;
                    }
                }
            }
            .overlay-price {
                padding: 10px 20px;
            }

            .missing-founded-phone {
                padding: 10px 20px;
            }
        }
    }

    .order-collars-container {
        width: 100% !important;
        padding: 0 !important;
        .collar-order-modal-container {
            flex-direction: column;
            width: 100% !important;
            .slideshow-and-imglist-and-description {
                width: 100% !important;
                max-width: 95% !important;
                margin: auto;

                .slideshow-and-imglist {
                    width: 100% !important;
                    height: 450px !important;

                    .collar-manual-slideshow-container {
                        img {
                            width: 100% !important;
                            height: 100% !important;
                            object-fit: contain !important;
                        }

                        .imglist {
                            gap: 10px;
                        }
                    }
                }
                .collar-description-block-container {
                    width: 100% !important;
                    padding: 0 !important;
                    margin: auto;
                }
            }
            .collar-header-and-options-container {
                width: 100% !important;
                row-gap: 15px !important;
                padding: 0 10px;
                background: var(--bodyback);
                border-radius: 0 !important;
                -webkit-border-radius: 0 !important;
                -moz-border-radius: 0 !important;
                -ms-border-radius: 0 !important;
                -o-border-radius: 0 !important;

                .separate-container {
                    padding: 25px 20px !important;
                    // background: transparent !important;
                }
                .collar-header {
                    justify-content: center !important;
                }

                p.qr-collar-price {
                    justify-content: center !important;
                }

                .select-color-container {
                    .select-color {
                        .color-button-container {
                            justify-content: center !important;
                        }
                    }
                }

                .qr-without-collar {
                    flex-wrap: wrap;
                }
                .qr-tag-size {
                    gap: 15px !important;

                    button {
                        cursor: pointer;
                        // background: var(--bodyback-grey);
                        border-color: lightgrey;
                    }
                }
                form {
                    .input-container {
                        // row-gap: 15px !important;
                        input {
                            width: 100% !important;
                            //

                            // padding: 20px 30px !important;
                        }

                        .pets-info-for-qr {
                            .pet-photo-container {
                                margin: 15px auto 5px auto !important;
                            }
                        }
                    }
                    .separate-container {
                        .qr-collar-rules {
                            gap: 15px;

                            input[type="checkbox"] {
                                padding: 10px !important;
                            }
                        }
                    }
                }
                .wrapper {
                    width: 100% !important;
                    .select-button {
                        // padding: 20px 30px !important;
                    }
                }
            }
        }
    }
    .qr-collar-instruction-container {
        margin: 0 auto !important;
    }
    .qr-collar-order-container {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 40px !important;
    }
    .video-instruction {
        width: 100%;
        height: auto !important;
        max-height: auto !important;
    }
    .custom-drop-down {
        background: var(--bodyback-grey) !important;
    }
    .select-color-container {
        .select-color {
            .color-button-container {
                // display: grid !important;
                // grid-template-columns: repeat(2, 1fr) !important;
                button {
                    justify-content: flex-start !important;
                }
            }
        }
    }
    .post-page-main-container {
        width: calc(100% - 15px) !important;

        .modal-for-img {
            .full-img-box {
                .close-imgbox {
                    top: 40px;
                    right: 10px !important;
                }
                img.modal-img {
                    width: 95%;
                    height: auto !important;
                }
            }
        }
        .city-view-id-container {
            gap: 14px !important;
            padding: 0 !important;
        }
        .post-img-info-container {
            flex-direction: column;
            width: 100% !important;
            padding: 0 !important;

            .right-container-for-banner-in-single-post {
                row-gap: 7px !important;
                width: 100% !important;
                max-width: 100% !important;
                margin: auto;
                padding: 0 !important;

                img {
                    width: calc(100vw - 30px) !important;
                    height: 160px !important;
                    max-height: 160px !important;
                    object-fit: cover;
                    // border-radius: 0 !important;
                    // -webkit-border-radius: 0 !important;
                    // -moz-border-radius: 0 !important;
                    // -ms-border-radius: 0 !important;
                    // -o-border-radius: 0 !important;
                }
            }
            .post-img-and-info-container {
                flex-direction: column !important;
                width: 100% !important;
                max-width: 100% !important;
                .post-page-img-gallery {
                    flex-direction: column !important;
                    gap: 6px !important;
                    margin: 0 !important;
                    .slideshow-container {
                        width: 100% !important;
                        min-height: 400px !important;
                        max-height: 400px !important;
                        // border-radius: 0 !important;
                        // -webkit-border-radius: 0 !important;
                        // -moz-border-radius: 0 !important;
                        // -ms-border-radius: 0 !important;
                        // -o-border-radius: 0 !important;
                        img {
                            // border-radius: inherit !important;
                            // -webkit-border-radius: inherit !important;
                            // -moz-border-radius: inherit !important;
                            // -ms-border-radius: inherit !important;
                            // -o-border-radius: inherit !important;
                        }
                    }
                    .img-list-container {
                        flex-direction: row !important;
                        justify-content: center !important;
                        width: 100% !important;
                        max-width: 100% !important;
                        height: auto !important;
                        // padding: 0 !important;
                        margin: 0 !important;
                        // border-radius: 0 !important;
                        // -webkit-border-radius: 0 !important;
                        // -moz-border-radius: 0 !important;
                        // -ms-border-radius: 0 !important;
                        // -o-border-radius: 0 !important;
                        img {
                            width: 70px !important;
                            height: 70px !important;
                            // border-radius: 0 !important;
                            // -webkit-border-radius: 0 !important;
                            // -moz-border-radius: 0 !important;
                            // -ms-border-radius: 0 !important;
                            // -o-border-radius: 0 !important;
                        }
                        // &::-webkit-scrollbar-track {
                        //     margin: 0 15px;
                        // }
                    }
                }
                .seller-info-container-static {
                    flex-direction: column !important;
                    width: 100% !important;
                    padding: 15px !important;
                    margin: 0 auto !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    border-radius: 0 !important;
                    -webkit-border-radius: 0 !important;
                    -moz-border-radius: 0 !important;
                    -ms-border-radius: 0 !important;
                    -o-border-radius: 0 !important;
                    .seller-phone-message-outside-container {
                        flex-direction: column !important;
                        justify-content: center !important;
                        align-items: center !important;
                        width: 100% !important;
                        margin: auto !important;
                        padding-right: 0 !important;

                        .seller-phone {
                            width: 100% !important;
                            padding: 20px !important;
                            background: #26b753 !important;
                            border-radius: $secondary-border-radius !important;
                            -webkit-border-radius: $secondary-border-radius !important;
                            -moz-border-radius: $secondary-border-radius !important;
                            -ms-border-radius: $secondary-border-radius !important;
                            -o-border-radius: $secondary-border-radius !important;
                            svg {
                                width: 23px !important;
                                height: 23px !important;
                                path {
                                    stroke: white !important;
                                }
                            }

                            p {
                                font-size: 1rem !important;
                                color: white !important;
                            }
                        }
                        .send-message {
                            width: 100% !important;
                            padding: 20px !important;
                            background: var(--bodyback) !important;
                            border-radius: $secondary-border-radius !important;
                            -webkit-border-radius: $secondary-border-radius !important;
                            -moz-border-radius: $secondary-border-radius !important;
                            -ms-border-radius: $secondary-border-radius !important;
                            -o-border-radius: $secondary-border-radius !important;

                            svg {
                                width: 23px !important;
                                height: 23px !important;
                            }

                            p {
                                font-size: 1rem !important;
                            }
                        }
                    }
                    .price {
                        width: 100% !important;
                        padding: 20px;
                        // background: rgba(55, 55, 55, 1);

                        p {
                            // color: white;
                            padding: 0 17px !important;
                        }
                        .currency {
                            width: fit-content !important;
                        }
                    }
                    .price-with-deal {
                        width: 100% !important;
                        padding: 20px;
                        // background: rgba(55, 55, 55, 1);
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        p {
                            color: black;
                        }
                    }
                    .adopt-singlepost,
                    .founded-singlepost,
                    .missing-singlepost {
                        width: 100% !important;
                        padding: 20px !important;
                        background: transparent !important;
                        border-radius: $secondary-border-radius !important;
                        -webkit-border-radius: $secondary-border-radius !important;
                        -moz-border-radius: $secondary-border-radius !important;
                        -ms-border-radius: $secondary-border-radius !important;
                        -o-border-radius: $secondary-border-radius !important;

                        p {
                            font-size: 1rem !important;
                            color: black !important;
                        }
                    }
                }
                .post-description-and-info-main-container {
                    width: 100% !important;
                    .post-description-container {
                        width: 100% !important;
                        margin-bottom: 0 !important;
                        // border-radius: 0 !important;
                        // -webkit-border-radius: 0 !important;
                        // -moz-border-radius: 0 !important;
                        // -ms-border-radius: 0 !important;
                        // -o-border-radius: 0 !important;
                    }
                    .sticky-banner-container2 {
                        width: 100% !important;
                        margin: 15px auto !important;
                    }
                    .post-pet-info-container {
                        width: 100% !important;
                        margin-top: 0 !important;
                        // border-radius: 0 !important;
                        // -webkit-border-radius: 0 !important;
                        // -moz-border-radius: 0 !important;
                        // -ms-border-radius: 0 !important;
                        // -o-border-radius: 0 !important;
                        .post-pet-info {
                            label {
                                p {
                                }
                                input[type="text"] {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .card-4-container {
        grid-template-columns: repeat(1, 1fr) !important;
        // padding: 10px !important;
        .card-4 {
            gap: 28px;
            width: 90%;
            margin: auto;
        }
    }
    /****START CONTACT US PAGE****/
    .contact-container {
        flex-direction: column !important;
        gap: 25px !important;
        margin: 0 !important;
        iframe.map {
            width: 100% !important;
            height: 300px !important;
        }
        .contact-info {
        }
    }
    /****FINISH CONTACT US PAGE****/
    .verify-account {
        flex-direction: column;
        width: 80%;
        margin: 150px auto;
        padding: 20px 5px !important;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    .success-payment {
        flex-direction: column;
        width: 80%;
        margin: 150px auto;
        padding: 20px 5px !important;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    .userpanel-container {
        width: calc(100% - 15px) !important;
        gap: 0 !important;
        margin: 20px auto !important;
        .userpanel-left-container {
            display: none;
        }
        .userpanel-screen-main-container {
            width: 100% !important;
            .add-post-container {
                form.add-post {
                    .fields-container {
                        .for-currency-price-deal {
                            flex-wrap: wrap;
                            // flex-direction: column !important;
                        }
                        .wrapper2 {
                            padding: 10px !important;
                        }
                    }
                    .upload-post-img {
                        margin: 0 !important;
                        .upload-photo {
                            width: 70px !important;
                            height: 70px !important;
                            p {
                                display: none !important;
                            }
                            input[type="file"] {
                                position: absolute;
                                opacity: 0;
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                                padding: 0 !important;
                                margin: 0;
                                background-color: $primary-bg;
                            }
                        }
                        .uploaded-photo-box {
                            width: 70px !important;
                            height: 70px !important;
                        }
                    }
                    input[type="submit"] {
                        width: 100% !important;
                        margin: 15px 0 !important;
                    }
                }
            }
            .active-post-container {
                width: 100% !important;
                padding: 0 !important;
                .myactive-post-lit-cont {
                    flex-direction: column !important;
                    gap: 7px;
                    max-height: 100% !important;
                    // overflow: hidden;
                    div.act-post-img {
                        width: 100% !important;
                        min-width: 100%;
                        min-height: 250px !important;
                        max-height: 250px !important;
                    }
                    .myactive-post-info-container {
                        padding: 0 4px;
                        .myactive-post-info-inside-container {
                            gap: 15px;
                            border: 1px solid transparent;
                            ul {
                                // flex-direction: column;
                                justify-content: space-between !important;
                                align-items: flex-start;
                                flex-wrap: wrap;
                                width: fit-content !important;
                                max-width: 100% !important;
                                gap: 14px !important;
                                li {
                                    font-size: 0.9rem !important;
                                    span {
                                        font-size: 0.9rem;
                                    }
                                }
                                span {
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }
                    .vip-edit-delete-container {
                        width: 100% !important;
                        height: auto !important;
                        padding: 10px;
                        padding-right: 0 !important;
                        background: var(--bodyback-grey);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                        .edit-delete {
                            flex-direction: row !important;
                            justify-content: center !important;
                            gap: 80px !important;
                            opacity: 1 !important;
                        }
                    }
                }
            }
            .personal-info-container {
                form.user-information {
                    flex-direction: column !important;
                    align-items: center !important;
                    gap: 50px;

                    .screen-user-photo {
                        width: 80%;
                        .photo {
                            img {
                            }
                        }
                        .upload-user-photo-btn {
                        }
                    }
                    .user-information-inside-container {
                        justify-content: center;
                        gap: 20px;

                        .user-update-fields {
                            width: 100% !important;

                            input {
                                width: 100%;
                                padding: 20px;
                            }
                            select {
                                width: 100%;
                                padding: 20px;
                            }
                        }

                        input.submit {
                            width: 100%;
                            padding: 20px;
                        }
                    }
                }
                form.change-password {
                    flex-direction: column;
                    width: 100%;
                    padding: 20px;

                    .user-update-fields {
                        width: 100% !important;

                        input[type="password"] {
                            width: 100%;
                            padding: 20px;
                        }
                    }

                    input[type="submit"] {
                        width: 100%;
                        padding: 20px;
                    }
                }
            } // END PERSONAL INFO CONTAINER
            .generate-qr-container {
                width: 100%;
                .qr-collar-order-buttons-container {
                    flex-direction: column;
                    button,
                    a {
                        width: 80%;
                        padding: 16px;
                    }
                }
                .purchased-collars {
                    .purchased-collars-cards-container {
                        grid-template-columns: repeat(1, 1fr);
                        // padding: 0 15px;
                        .modal-background {
                            background: rgba(0, 0, 0, 0.9);
                            .qr-collar-buttons-instruction-container {
                                position: absolute;
                                top: 0;
                                left: 0;
                                justify-content: flex-start;
                                width: 100%;
                                height: 100%;
                                transform: none;
                                -webkit-transform: none;
                                -moz-transform: none;
                                -ms-transform: none;
                                -o-transform: none;
                            }
                        }
                        .purchased-collars-card {
                            flex-direction: column;
                            justify-content: center;
                            // height: 100%;

                            .pet-img-and-buttons {
                                align-items: center;
                                width: 100%;

                                .purchased-collars-card-pet-name {
                                    padding: 10px 0;
                                }
                                // .img-box {
                                //     width: 170px;
                                //     height: 170px;
                                //     min-width: 170px;
                                //     min-height: 170px !important;
                                // }
                                .edit-delete-container {
                                    padding: 7px 10px;
                                    .hide-instruction-btn {
                                        display: none !important;
                                    }
                                    button {
                                        opacity: 1;
                                    }
                                    .update-owner-info {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .pet-info-outside-container {
                        position: relative !important;
                        // margin-top: 5px;
                        .pet-info-top-buttons-container {
                            display: flex !important;

                            .close-update-container {
                                display: none !important;
                            }
                        }
                        .pet-info-container {
                            .pet-info-container-title {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
            .favorite-post-container {
                padding: 0 10px;
                .favorite-post-inside-container {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
            .user-message-container {
                flex-direction: column;
                height: 100%;
                padding: 10px;
                .user-list-container {
                    flex-direction: row;
                    width: 100%;
                    max-width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                    .user-name {
                        p {
                            white-space: nowrap;
                        }
                    }
                }
                .read-send-message-container {
                    width: 100%;
                    .read-message {
                        height: 30vh;
                    }
                    textarea {
                        height: 12vh;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .confirmation-background-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 35px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
        .confirmation-inside-container {
            width: 100%;
        }
    }
    .scroll-up {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .footer {
        display: none;
    }

    .our-social-pages-and-contact-container {
        align-items: center !important;
        padding: 0;

        p {
            margin: 0;
        }
    }

    .footer-for-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 21px;
        padding-top: 30px;
        .footer-for-mobile-title {
            width: fit-content;
            margin: auto;
            p {
                font-size: 0.85rem;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color: black;
            }
            ul {
                display: none;
                padding: 7px 0;
                li {
                    text-align: center;
                    color: $primary-color;
                    margin: 13px 0;
                    list-style-type: none;
                    a {
                        font-size: 0.9rem;
                        text-decoration: none;
                        color: black;
                    }
                    p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 11px;
                        color: black;
                        cursor: default;
                        font-size: 0.85rem;
                        font-weight: 500;
                        .our-services-check {
                            width: 15px;
                            height: 15px;
                            background-image: url("../../../public/svg/check-without-circle.svg");
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
        .footer-for-mobile-title.active {
            ul {
                display: block;
            }
        }
        .social-icons-inside-container-for-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5vh;
            margin: 3vh auto;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.3vh 0.1vh;
                border: none;
                svg {
                    width: 17px;
                    height: 17px;
                    path {
                        fill: black;
                    }
                }
            }
        }
    }
    .download-app-and-user-counter {
        width: 100%;
        margin: 7px auto;
        .download-app {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25px;
            margin: auto;
            a {
                img {
                    height: 28px;
                    max-height: 28px;
                }
            }
        }
    }
    .copyright {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
        padding: 14px 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        p {
            font-size: 0.75rem;
            color: black;
        }
    }
    .modal-background {
        background: white !important;
        padding: 0 !important;
    }
    .login-signup-parent-modal-container {
        width: 100%;
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
        .login-advertise-container {
            display: none;
        }
    }
    .login-container {
        width: 100%;
        padding: 40px 20px;
    }
    .registration-container {
        width: 100%;
        padding: 40px 20px;
    }
    .recover-password {
        width: 100%;
        padding: 20px !important;
        margin: 100px 0 !important;
        .recover-password-title {
            margin: 14px 3.5px 40px 3.5px;
        }
        p {
            font-size: 1rem;
            margin: 7px 3.5px;
        }
        form {
            .check-code {
                display: none;
                img {
                    width: 40%;
                }
                input {
                    width: 50%;
                }
            }
            button[type="submit"] {
                margin: 25px 0 !important;
            }
        }
    }
    /****START EMAIL RESET PASSWORD CONTAINER****/
    .email-reset-password {
        width: 100%;
        form {
            .check-code {
                display: none; // flex;
            }
        }
    }
    /****END EMAIL RESET PASSWORD CONTAINER****/
    .contact-container {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px auto !important;

        .contact-info {
            h3 {
                display: none;
            }
        }
    }

    .rules-parent-container {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        .rules-inside-container {
            padding: 7px;
            .table-of-content {
                display: none;
            }
            .editor {
                width: 100%;
                padding: 20px;
            }
        }
    }

    .border-none {
        border: none !important;
    }

    .binder {
        display: none !important;
    }

    .find-pet-info-container {
        // display: block !important;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // gap: 0;
        width: 100%;
        height: 100vh;
        flex: 1 !important;
        // margin: 0 auto !important;
        padding: 0;
        // padding-bottom: 20px;
        background: var(--bodyback) !important;

        .find-pet-info-container-header {
            flex-direction: column;
            width: 100% !important;
            // height: auto !important;
            // margin-top: 20px;
            // padding: 0 0 35px 0;
            // padding: 25px;
            background: $sec-design-color !important;

            .pet-photo-and-name-container {
                position: relative;
                width: 100%; // 250px;
                height: 100%; // 250px;
                max-height: 370px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // width: fit-content;
                overflow: hidden;
                // height: 100% !important;
                // padding: 3px !important;
                margin: auto !important;
                box-shadow: none !important;
                background: var(--bodyback-grey) !important;
                // border: 5px solid rgba(55, 55, 55, 1);
                // border-radius: 50%;
                // -webkit-border-radius: 50%;
                // -moz-border-radius: 50%;
                // -ms-border-radius: 50%;
                // -o-border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    //min-width: 250px;
                    //100%:;
                    //min-height: 250px;
                    //400px:;
                    //max-width: 250px;
                    //100%:;
                    //max-height: 250px;
                    //400px:;
                    //margin: 50px auto 30px auto !important;
                    padding: 0 !important;
                    object-fit: contain;
                    border-radius: inherit;
                    -webkit-border-radius: inherit;
                    -moz-border-radius: inherit;
                    -ms-border-radius: inherit;
                    -o-border-radius: inherit;
                    // border: 10px solid $sec-design-color;
                    // box-shadow: var(--shadow);
                    box-shadow: none !important;
                }

                .dropdown-menu-for-findpet-page {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    left: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    // width: 100%;
                    // background: red;

                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: fit-content;
                        padding: 5px 12px;
                        background: white;
                        border: none;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;

                        &:hover {
                            .dropdown-menu-for-findpet-page-droped-box {
                                display: flex;
                            }
                        }

                        svg {
                            width: 27px;
                            height: auto;
                        }

                        .dropdown-menu-for-findpet-page-droped-box {
                            display: none;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            padding: 10px 20px;
                            margin-top: 5px;
                            background: white;
                            border-radius: inherit;
                            -webkit-border-radius: inherit;
                            -moz-border-radius: inherit;
                            -ms-border-radius: inherit;
                            -o-border-radius: inherit;

                            a {
                                font-size: 1rem;
                                font-weight: bold;
                                white-space: nowrap;
                                margin: 10px 0;
                            }
                        }
                    }

                    .find-pet-info-language-switcher {
                        // position: absolute;
                        // top: 25px;
                        // right: 25px;
                        position: relative;
                        // z-index: 1;
                        display: flex !important;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;
                        width: fit-content;
                        padding: 7px 10px;
                        background: white; // rgba(255, 255, 255, 0.7);
                        // border-top-left-radius: $third-border-radius;
                        // border-bottom-left-radius: $third-border-radius;
                        transition: 0.5s;
                        -webkit-transition: 0.5s;
                        -moz-transition: 0.5s;
                        -ms-transition: 0.5s;
                        -o-transition: 0.5s;
                        // box-shadow: var(--shadow);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;

                        img {
                            // position: absolute;
                            width: 35px;
                            // height: 21px;
                            // border-radius: inherit;
                            // -webkit-border-radius: inherit;
                            // -moz-border-radius: inherit;
                            // -ms-border-radius: inherit;
                            // -o-border-radius: inherit;
                        }
                    }
                }

                .pet-name {
                    //display: none;
                    position: absolute;
                    bottom: 40px;
                    left: 20px;
                    width: fit-content;
                    max-width: 100%;
                    color: white;
                    text-align: center !important;
                    font-size: 1.2rem;
                    font-weight: 600;
                    letter-spacing: 1.5px;
                    padding: 2px 10px;
                    margin: 0 auto;
                    color: white;
                    background: $sec-design-color;
                    //rgba(55,55,55,1):;
                    //border-top-right-radius: 24px;
                    //border-bottom-right-radius: 24px;
                    //margin-top: -80px;
                    border-radius: 12px;
                    -webkit-border-radius: 12px;
                    -moz-border-radius: 12px;
                    -ms-border-radius: 12px;
                    -o-border-radius: 12px;

                    // transform: translateX(-50%);
                    // -webkit-transform: translateX(-50%);
                    // -moz-transform: translateX(-50%);
                    // -ms-transform: translateX(-50%);
                    // -o-transform: translateX(-50%);
                    text-transform: uppercase !important;
                }
            }
        }

        .find-pet-info-container-body {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 35px 15px;
            margin-top: -25px;
            background: var(--bodyback);
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.2);

            .find-pet-info-call-owner {
                position: relative;
                z-index: 1;
                display: flex !important;
                //flex-direction: column;
                justify-content: space-between !important;
                align-items: center;
                gap: 15px;
                width: 100% !important;
                padding: 8px 11px !important;
                margin: 15px auto !important;
                //margin-bottom: 0 !important;
                background: rgba(55, 55, 55, 1);
                border-radius: $third-border-radius;
                -webkit-border-radius: $third-border-radius;
                -moz-border-radius: $third-border-radius;
                -ms-border-radius: $third-border-radius;
                -o-border-radius: $third-border-radius;
                button,
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2vh;
                    width: fit-content;
                    font-size: 2vh;
                    text-decoration: none;
                    //font-weight: 500;
                    cursor: pointer;
                    padding: 14px;
                    border: none;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    svg {
                        width: 22px;
                        height: 22px;
                        path {
                            stroke-width: 0.8 !important;
                            stroke: rgba(55, 55, 55, 1) !important;
                            fill: rgba(55, 55, 55, 1);
                            fill-opacity: 0.24;
                        }
                    }
                }
                .call-owner {
                    color: white;
                    background: #26b753 !important;
                    border-color: transparent !important;
                    svg path {
                        stroke-width: 0.8 !important;
                        stroke: white !important;
                        fill: white;
                        fill-opacity: 1;
                    }
                }
            }
            .logo-button-container {
                position: relative;
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                // margin-top: -30px;
                background: transparent;
                // box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45);
                // border-radius: 0;
                // -webkit-border-radius: 0;
                // -moz-border-radius: 0;
                // -ms-border-radius: 0;
                // -o-border-radius: 0;
                // border-top-left-radius: 30px;
                // border-top-right-radius: 30px;
                .logo-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px 0 17px 0;
                    background: var(--background);
                    img {
                        height: 27px;
                        object-fit: contain;
                    }
                }
            }
            .find-pet-info-outside-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 100%;
                padding: 30px 0;

                .pet-type {
                    display: block;
                }

                .find-pet-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 20px;
                    width: 100%;
                    // padding: 10px 25px;
                    background: transparent;
                    border-radius: 0; // 23px:;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    -ms-border-radius: 0;
                    -o-border-radius: 0;

                    .find-pet-info-header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        .header-text {
                            position: relative;
                            width: 100%;
                            color: white;
                            font-size: 0.95rem;
                            font-weight: 800;
                            text-align: center;
                            padding: 8.5px 14px;
                            margin-top: 20px;
                            background: $sec-design-color;
                            border-radius: $third-border-radius;
                            -webkit-border-radius: $third-border-radius;
                            -moz-border-radius: $third-border-radius;
                            -ms-border-radius: $third-border-radius;
                            -o-border-radius: $third-border-radius;

                            &:after {
                                display: none;
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 30px;
                                height: 100%;
                                background: $primary-bg;
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                            }
                        }
                    }
                    .label-container {
                        width: 100%;
                        // padding: 0 10px;

                        span {
                            color: grey;
                            font-size: 0.9rem;
                            font-weight: 600;
                            padding: 0 20px;
                        }

                        p {
                            width: 100%;
                            //color: white;
                            font-size: 1rem;
                            font-weight: 500;
                            padding: 20px;
                            margin-top: 5px;
                            //7px14px:;
                            //margin-left: 1vh;
                            background: var(--bodyback-grey);
                            //border-bottom: 2px solid $hover-color;
                            // border: 2px solid var(--bodyback-grey);

                            white-space: pre-wrap; /* CSS3 */
                            white-space: -moz-pre-wrap; /* Firefox */
                            white-space: -pre-wrap; /* Opera <7 */
                            white-space: -o-pre-wrap; /* Opera 7 */
                            word-wrap: break-word;

                            border-radius: 24px;
                            -webkit-border-radius: 24px;
                            -moz-border-radius: 24px;
                            -ms-border-radius: 24px;
                            -o-border-radius: 24px;
                        }

                        .with-svg {
                            display: none; // flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 20px;
                            padding: 0 0 0 20px;

                            a {
                                padding: 13px 20px;
                                background: #26b753;
                                border-left: 10px solid white;
                                border-top-right-radius: inherit;
                                border-bottom-right-radius: inherit;

                                svg {
                                    width: 30px;
                                    height: auto;

                                    path {
                                        fill: white;
                                    }
                                }
                            }
                        }
                    }

                    // .vaccinated {
                    //   color: white;
                    //   background: #26b753;
                    // }
                    // .not-vaccinated {
                    //   color: white;
                    //   background: red;
                    // }
                }
            }

            .locked-buttons {
                display: none; // flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 80%;
                padding: 17px 0;
                margin: 20px auto;
                // background: rgba(55, 55, 55, 0.5);
                border: 3px solid rgba(55, 55, 55, 1);
                border-radius: $third-border-radius;
                -webkit-border-radius: $third-border-radius;
                -moz-border-radius: $third-border-radius;
                -ms-border-radius: $third-border-radius;
                -o-border-radius: $third-border-radius;
                img {
                    width: 25px;
                    height: 25px;
                }
                p {
                    font-size: 1.1rem;
                    font-weight: 700;
                }
            }

            .locked-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 85%;
                margin: 20px auto;
                padding: 60px 20px;
                //background: rgba(0, 0, 0, 0.3);
                border: 3px solid rgba(55, 55, 55, 1);
                border-radius: 24px;
                -webkit-border-radius: 24px;
                -moz-border-radius: 24px;
                -ms-border-radius: 24px;
                -o-border-radius: 24px;

                .locked-info-svg {
                    width: 45px;
                    height: 45px;
                }

                .locked-info-text {
                    font-size: 1.1rem;
                    font-weight: 700;
                }
            }

            .find-pet-info-container-locked-tbns {
                display: none; // flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                margin: 30px auto;

                a {
                    color: white;
                    font-size: 1rem;
                    padding: 14px 20px;
                    background: rgba(55, 55, 55, 1);
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                }
            }
            .find-pet-info-language-switcher-for-desktop {
                display: flex !important;
                margin: 30px auto !important;
                // background: red !important;
            }
        }
    }

    .dark-mode {
        .header-middle-parent-container {
            .header-middle-inside-container {
                .search-box-and-modal-filter-button {
                    display: none !important;
                }
                .header-right-container {
                    .login-signup-container {
                        button {
                            border-color: transparent !important;
                            background: transparent !important;
                        }
                    }
                    .user-head-container {
                        button {
                            background: transparent !important;
                            border-color: transparent !important;
                        }
                    }
                    .user-head-container.active {
                        button {
                            border-color: transparent !important;
                            background: transparent !important;
                        }
                    }
                    .language-and-darkmode {
                        .lang-switcher-container {
                            button {
                                background: transparent !important;
                                border-color: transparent !important;
                            }
                        }
                        .lang-switcher-container.active {
                            button {
                                border-color: transparent !important;
                                background: transparent !important;
                            }
                        }
                    }
                    .dark-mode-btn {
                        background: transparent !important;
                        border-color: transparent !important;
                    }
                }
            }
        }
        .head-nav {
            background-color: $dark-mode-background;
        }
        .site-main-filtre-out-container {
            // background-image: url("../../../public/img/back.jpg") !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            -ms-border-radius: 0 !important;
            -o-border-radius: 0 !important;
            .site-main-filter-single-search-container {
                border-radius: 0 !important;
                -webkit-border-radius: 0 !important;
                -moz-border-radius: 0 !important;
                -ms-border-radius: 0 !important;
                -o-border-radius: 0 !important;
            }
        }
    }
}
