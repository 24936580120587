@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.ourservice-main-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0%;
    padding: 0 3%;

    .ourservice-sec-container {
        display: block;
        width: 40%;
        height: 81vh;
        text-align: center;
        margin: 2% auto 2% auto;
        padding: 3%;
        box-shadow: $box-shadow2;
        background-color: $secondary-bg;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        &:hover {
            background-color: rgba(255, 255, 255, 0.8);
            -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,1);
            -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,1);
            box-shadow: 1px 1px 10px 0px rgba(0,0,0,1);
        }

        a {
            font-size: $secondary-fontsize;
            font-weight: $secondary-fontweight;
            line-height: 2;
        }

        div {
            width: 100%;
            margin: 0 0 7% 0;

            img {
                width: 70%;
                margin: 0 15%;
                border-radius: $primary-border-radius;
                -webkit-border-radius: $primary-border-radius;
                -moz-border-radius: $primary-border-radius;
                -ms-border-radius: $primary-border-radius;
                -o-border-radius: $primary-border-radius;
            }
        }

        hr {
            width: 70%;
            margin: 7% auto;
            border: 1px solid black;
        }

        .service-buttons-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10%;
            
            div {
                padding: 3%;
                text-align: center;
                margin: 0;
                cursor: pointer;
                background-color: $third-color;
                border: 1px solid $sec-design-color;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                &:hover {
                    background-color: $sec-design-color;

                    a {
                        color: white;
                    }
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}