@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.contact-container {
    width: calc(100% - 50px);
    max-width: 1100px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
    // background: white;
    // padding: 30px 20px;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    .map {
        width: 60%;
        height: 400px;
        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        gap: 14px;
        background-color: transparent;
        width: fit-content;
        margin: 0;

        h3 {
            // font-size: 1.3rem;
            margin: 10px 3px;
            margin-top: 0;
        }

        div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 14px;
            width: fit-content;

            div.contact-img {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px;
                background-color: $sec-design-color;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                svg {
                    width: 25px;
                    height: 25px;

                    path {
                        stroke: white;
                    }
                }
            }

            div.contact-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 3px;

                p {
                    color: $primary-color;
                    font-size: 0.8rem;
                    font-weight: normal;
                    margin: 0;

                    &:nth-child(2) {
                        font-size: 0.85rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
