@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.dark-mode {
    background: $dark-mode-background; // #202328
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;

    * {
        color: $sec-design-color-dark;
    }

    .footer-parent-container {
        background: $dark-mode-background;
    }

    .confirmation-background-container {
        .confirmation-inside-container {
            background: $dark-mode-background;

            p {
                color: $sec-design-color-dark;
            }

            .confirmation-buttons-container {
                button {
                    background: $dark-mode-color1;
                }
            }
        }

        .confirmation-warning-text {
            color: $sec-design-color-dark;

            svg path {
                fill: $sec-design-color-dark;
            }
        }
    }

    .header-top-container {
        background: $dark-mode-color1;

        .header-top-inside-container {
            .header-left-container {
                .social-icons {
                    svg path {
                        fill: $dark-mode-color3;
                    }
                }
            }
        }

        .header-right-info-container {
            p {
                svg path {
                    stroke: $dark-mode-color3;
                }
            }
        }
    }

    .header-middle-parent-container {
        background: $dark-mode-background !important;
        border-color: $dark-mode-color1;
        border-bottom-color: $dark-mode-color1;
        transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;

        .header-middle-inside-container {
            .logo-box {
                .logo-img-box {
                    // background-image: url("../../public/img/new-logo6.png");
                }

                a {
                    color: $sec-design-color !important;

                    span {
                        color: $sec-design-color !important;
                    }
                }
            }

            .header-search-container {
                background: $dark-mode-color1;

                .select-category-button {
                    color: white;
                    border-right-color: $dark-mode-background;
                }

                input[type="search"] {
                    &::placeholder {
                        color: white;
                    }
                }

                input[type="submit"] {
                    background-image: url(../../public/svg/search-white.svg);
                }
            }

            .header-right-container {
                * {
                    color: $dark-mode-color3 !important;
                }

                .menu-for-mobile {
                    svg path {
                        stroke: $dark-mode-color3;
                    }
                }

                .language-and-darkmode {
                    background: transparent;

                    .lang-switcher-container {
                        button {
                            background: $dark-mode-color1;
                            border-color: $dark-mode-color1;
                        }

                        .lang-list-container {
                            background: $dark-mode-color1;
                            -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                            -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

                            ul {
                                li {
                                    &:hover {
                                        background: $dark-mode-background;
                                    }
                                }
                            }

                            hr {
                                background: $dark-mode-background;
                                border-color: $dark-mode-background;
                            }
                        }

                        .lang-list-container::after {
                            background: $dark-mode-color1;
                        }
                    }

                    .lang-switcher-container.active {
                        background: transparent;

                        button {
                            svg.earth path {
                                fill: $sec-design-color;
                            }

                            span {
                                color: $sec-design-color;
                            }
                        }
                    }

                    .dark-mode-btn {
                        background: $dark-mode-color1;
                        border-color: $dark-mode-color1;

                        .sun {
                            color: $sec-design-color-dark;

                            &:hover {
                                color: $sec-design-color;
                                transition: all 0s;
                                -webkit-transition: all 0s;
                                -moz-transition: all 0s;
                                -ms-transition: all 0s;
                                -o-transition: all 0s;
                            }
                        }
                    }
                }

                .login-signup-container {
                    button {
                        border-color: $dark-mode-color1;

                        &:hover {
                            border-color: $sec-design-color;
                        }

                        svg path {
                            fill: $dark-mode-color3;
                            stroke: $dark-mode-color3;
                        }
                    }
                }

                .user-head-container {
                    a {
                        color: $sec-design-color-dark;
                    }

                    button {
                        background: $dark-mode-color1;
                        border-color: $dark-mode-color1;

                        svg.logedin-user path {
                            stroke: transparent;
                            fill: $dark-mode-color3;
                        }

                        a.user-fullname {
                            color: $dark-mode-color3;
                        }
                    }

                    .user-dropdown-container {
                        background: $dark-mode-color1;
                        border: none;
                        -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                        -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

                        &:hover {
                            background: $dark-mode-color1;
                        }

                        .user-info-mini-container {
                            a.user-fullname {
                                span {
                                    color: $sec-design-color-dark;
                                }
                            }
                        }

                        hr {
                            border-color: $dark-mode-background;
                        }

                        .hr-2 {
                            border-color: $dark-mode-background;
                        }

                        ul li {
                            &:hover {
                                background: $dark-mode-background;

                                a {
                                    color: $sec-design-color-dark;
                                }
                            }
                        }

                        svg {
                            path {
                                stroke: $dark-mode-color3;
                            }
                        }
                    }

                    .user-dropdown-container::after {
                        background: $dark-mode-color1;
                    }
                }

                .user-head-container.active {
                    button {
                    }
                }
            }
        }
    }

    hr.und-middle-cont {
        border-color: $dark-mode-color1;
    }

    .head-nav-outside-container {
        background: $dark-mode-color1;

        .head-nav {
            border-top-color: $dark-mode-color1;
            a {
                color: $sec-design-color-dark;

                &:hover {
                    color: $sec-design-color;
                    // background: rgba(220, 215, 201, 0.7);
                }
            }

            div.institutions-dropdown-button-container {
                .institutions-dropdown-button {
                    color: $dark-mode-color3;

                    svg path {
                        stroke: $dark-mode-color3;
                    }
                }

                .institutions-dropdown-container {
                    background: $dark-mode-color1;
                    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

                    a {
                        color: $dark-mode-color3;

                        &:hover {
                            color: $sec-design-color;
                        }
                    }
                }
            }

            div.institutions-dropdown-button-container.active {
                .institutions-dropdown-button {
                    color: $sec-design-color;

                    svg path {
                        stroke: $sec-design-color;
                    }
                }
            }
        }
    }

    .qr-tags-container {
        // background: rgb(216, 215, 215);
        // background-image: url("../../public/img/qr-tag-back3.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // background-attachment: fixed;
        // border: 1px solid black;
    }

    .qr-tag-list-container {
        .qr-tag-list-inside-container {
            .prev,
            .next {
                background: $dark-mode-color1;

                &:hover {
                    background: black;
                }
            }
        }
    }

    .qr-collar-order-buttons-container-for-home {
        .order-buttons-inside-container {
            button {
                background: $dark-mode-color1;
                border-color: transparent;

                &::after {
                    opacity: 0.2;
                }

                svg path {
                    stroke: $sec-design-color-dark;
                }
            }

            a {
                color: $primary-color;
                background: rgba(220, 215, 201, 0.5);
                background: $sec-design-color;

                ion-icon {
                    color: black;
                }
            }

            a.order-collars-page-links {
                color: $dark-mode-color3;
                background: $dark-mode-color1;

                svg path {
                    stroke: $dark-mode-color3;
                }
            }
        }

        .search-box-and-currency {
            .search-box {
                background: $dark-mode-color1;
                border-color: $dark-mode-color1;

                input[type="search"] {
                    &::placeholder {
                        color: $dark-mode-color3;
                    }
                }

                input[type="submit"] {
                    background-image: url(../../public/svg/search-white.svg);
                }
            }
        }
    }

    .site-main-filtre-out-container {
        .site-main-filter-single-search-container {
            background: $dark-mode-color1;

            .pets-button-in-filter-container {
                border-bottom-color: $dark-mode-background;

                .pets-button-container {
                    .mid-border {
                        border-left-color: $dark-mode-color1 !important;
                        border-right-color: $dark-mode-color1 !important;
                    }

                    .pets-button {
                        background: $dark-mode-background;
                        border-color: $dark-mode-background;

                        svg path {
                            fill: white;
                        }
                    }
                }

                .pets-button-container.active {
                    .pets-button {
                        background: $sec-design-color;

                        svg path {
                            fill: $sec-design-color-dark;
                        }

                        span {
                            color: $sec-design-color-dark;
                        }
                    }
                }

                .pets-documents {
                    background: transparent !important;

                    .wrapper {
                        background: transparent !important;

                        .select-button {
                            background: transparent !important;
                        }

                        .select-menu-dropdown {
                            background: $dark-mode-background;

                            ul.options {
                                label {
                                    color: $dark-mode-color3;

                                    input {
                                        background: $dark-mode-color1;
                                        border-color: $dark-mode-color1;

                                        &:checked {
                                            background: $dark-mode-color1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .currency {
                        background: rgba(63, 78, 79, 0.7);

                        .currency-switch {
                            svg path {
                                fill: $dark-mode-color3;
                            }
                        }

                        .active {
                            background: $dark-mode-background;
                        }
                    }
                }

                .clear-filters {
                    background: $dark-mode-background;

                    svg path {
                        stroke: $sec-design-color-dark;
                        fill: none;
                    }
                }
            }

            .search-box-and-currency {
                .search-box {
                    background: $dark-mode-background;
                    border: 1px solid $dark-mode-background;

                    &:hover {
                        border-color: $dark-mode-background;
                    }

                    input[type="search"] {
                        &::placeholder {
                            color: $sec-design-color-dark;
                        }
                    }

                    input[type="submit"] {
                        background-image: url(../../public/svg/search2.svg);
                    }
                }
            }

            .site-main-filtre {
                .wrapper {
                    .select-button {
                        background: $dark-mode-background !important;
                        border: none;

                        &:hover,
                        &:focus,
                        &:active {
                            background: $dark-mode-background;
                            border-color: $dark-mode-background;
                            // -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                            // -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                            // box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                        }

                        div {
                            color: $sec-design-color-dark;
                        }

                        svg path {
                            stroke: $sec-design-color-dark;
                        }
                    }

                    .select-button.active {
                        background: $dark-mode-background;
                    }

                    .select-menu-dropdown {
                        background: $dark-mode-color1;
                        -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                        -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

                        .select-search {
                            input {
                                color: $dark-mode-color3;
                                background: $dark-mode-background;
                                background-image: url(../../public/svg/search-white.svg);
                                background-repeat: no-repeat;
                                background-position: 15px center !important;
                                background-size: 17px;
                                text-align: left;
                                border-color: transparent;

                                &::placeholder {
                                    color: $dark-mode-color3;
                                }
                            }
                        }

                        ul.options {
                            &::-webkit-scrollbar-track {
                                background: transparent;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: $dark-mode-background;
                            }

                            // li {
                            //     color: $dark-mode-color3;

                            //     &:hover {
                            //         color: $dark-mode-color3;
                            //         background: $dark-mode-color1;
                            //     }

                            //     &:active {
                            //         color: $sec-design-color;
                            //     }
                            // }

                            label {
                                color: $dark-mode-color3;

                                &:hover,
                                &:focus {
                                    color: $sec-design-color;
                                }

                                &:active {
                                    color: $sec-design-color;
                                }

                                input.search-checkbox {
                                    background: $dark-mode-background;
                                    border-color: $dark-mode-background;

                                    &:checked {
                                        background: $dark-mode-background;
                                        border-color: $sec-design-color;
                                    }
                                }
                            }
                        }
                    }
                }

                .filter-price {
                    input[type="text"] {
                        background: $dark-mode-background;
                        border-color: $dark-mode-color1;

                        &::placeholder {
                            color: $sec-design-color-dark;
                        }
                    }

                    input.to[type="text"] {
                        border-right: none;
                    }
                }

                .filter-search-button-container {
                    background: $sec-design-color;

                    button.filtre-search-button {
                        color: $sec-design-color-dark;
                        // background: $dark-mode-background;
                        // background-image: url(../../public/svg/search-white.svg);
                    }
                }
            }

            .contact-details-outside-container {
                .contact-details-inside-container {
                    background: rgba(63, 78, 79, 0.7) !important;

                    span {
                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }
                }
            }
        }
    }

    .filter-left-version {
        .filter-left-version-inside-container {
            background: $dark-mode-color1;

            .search-box {
                background: $dark-mode-background;

                input[type="search"] {
                    &::placeholder {
                        color: white;
                    }
                }

                input[type="submit"] {
                    background-image: url(../../public/svg/search-white.svg);
                }
            }

            .pets-button-in-filter-container {
                background: $dark-mode-background;

                .pets-button-container-for-left-version {
                    .mid-border {
                        border-left-color: $dark-mode-color1 !important;
                        border-right-color: $dark-mode-color1 !important;
                    }

                    .pets-button {
                        border-color: $dark-mode-background;

                        &:hover {
                            background: transparent;
                        }

                        svg path {
                            fill: white;
                        }
                    }
                }

                .cats-button {
                    border-left-color: transparent;
                }

                .pets-button-container-for-left-version.active {
                    background: transparent;

                    .pets-button {
                        background: $sec-design-color;

                        svg path {
                            // fill: $sec-design-color;
                        }

                        span {
                            // color: $sec-design-color;
                        }
                    }
                }
            }

            .site-main-filtre {
                .wrapper {
                    .select-button {
                        border-color: $dark-mode-background;

                        div {
                            color: $dark-mode-color3;
                        }

                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }

                    .select-menu-dropdown {
                        background: transparent;

                        .select-search {
                            input {
                                color: $dark-mode-color3;
                                background: $dark-mode-background;
                                border-color: $dark-mode-color1;
                                background-image: url(../../public/svg/search-white.svg);

                                &::placeholder {
                                    color: $dark-mode-color3;
                                }
                            }
                        }

                        ul.options {
                            &::-webkit-scrollbar-track {
                                background: transparent;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: $dark-mode-color1;
                            }

                            label {
                                color: $dark-mode-color3;

                                &:hover {
                                    color: $sec-design-color;
                                    background: transparent;
                                }

                                input.search-checkbox {
                                    background: $dark-mode-background;
                                    border-color: $dark-mode-background;

                                    &:checked {
                                        border-color: $sec-design-color;
                                    }
                                }
                            }
                        }
                    }

                    .select-button.active {
                        background: transparent;
                    }
                }

                .filter-price {
                    input {
                        color: $dark-mode-color3;
                        border-color: $dark-mode-background;

                        &::placeholder {
                            color: $dark-mode-color3;
                        }

                        &:hover {
                            border-color: $dark-mode-background;
                        }
                    }
                }
            }

            .clear-filters-container {
                .clear-filters {
                    background: $dark-mode-background;

                    svg path {
                        stroke: $dark-mode-color3;
                    }
                }
            }
        }
    }

    .card {
        background: $dark-mode-color1;

        &:hover {
            background: $dark-mode-color1;
        }

        .card-info-container {
            .top-border-style {
                background: $dark-mode-color1;
            }
        }

        .missing-founded-phone {
            svg path {
                stroke: $dark-mode-color3;
            }
        }

        hr {
            background: $dark-mode-background !important;
            border-color: $dark-mode-background !important;
        }
    }

    .filter-of-company {
        .city-and-district-filter {
            .wrapper {
                .select-button {
                    background: $dark-mode-color1;
                    border-color: $dark-mode-color1;

                    div {
                        color: $sec-design-color-dark;
                    }

                    svg path {
                        stroke: $sec-design-color-dark;
                    }
                }
            }
        }

        .search-box {
            background: $dark-mode-color1;
            border-color: $dark-mode-color1;

            input[type="search"] {
                &::placeholder {
                    color: $sec-design-color-dark;
                }
            }

            input[type="submit"] {
                background-image: url(../../public/svg/search-white.svg);
            }
        }

        .keyword-container {
            svg path {
                stroke: $sec-design-color-dark;
            }

            .keyword {
                background: rgba(63, 78, 79, 0.5);
                border-color: rgba(63, 78, 79, 0.8);

                &:hover {
                    background: rgb(220, 215, 201, 0.5);
                    border-color: rgb(220, 215, 201, 0.7);
                }
            }

            .keyword.active {
                background: rgb(220, 215, 201, 0.5);
                border-color: rgb(220, 215, 201, 0.7);
            }
        }
    }

    .pagination {
        a {
            color: $dark-mode-color3;
        }
    }

    .pagination a.active {
        background: $dark-mode-color1;
    }

    .main-container {
        background: transparent;

        .left-container {
            .left-container-posts-header {
                .next-prev {
                    svg {
                        path {
                            stroke: darkgrey;
                            fill: transparent;
                        }

                        &:hover path {
                            stroke: $dark-mode-color3;
                        }
                    }
                }
            }

            .card-2-container {
                .card-2 {
                    background: $dark-mode-color1;
                    border-color: transparent;

                    &:hover {
                        box-shadow: none;
                    }

                    .card-2-info-container {
                        background: $dark-mode-color1;

                        .card-2-price-rate {
                            .low-rate {
                                background: $sec-design-color;
                            }

                            .price-currency {
                                .currency {
                                    .currency-switch {
                                        svg path {
                                            fill: $dark-mode-color3;
                                        }
                                    }

                                    .active {
                                        background: $dark-mode-background;
                                    }
                                }
                            }
                        }

                        .viewpost-and-buttons {
                            .postdate-view {
                                p {
                                    color: $dark-mode-color3 !important;
                                }
                            }

                            .post-buttons {
                                button {
                                    svg.save-button {
                                        path {
                                            stroke: $dark-mode-color3;

                                            &:hover,
                                            &:focus,
                                            &:active {
                                                stroke: $sec-design-color;
                                                fill: $sec-design-color;
                                            }
                                        }
                                    }

                                    svg.save-button.active path {
                                        stroke: $sec-design-color !important;
                                        fill: $sec-design-color;
                                    }

                                    svg.message path {
                                        stroke: $dark-mode-color3;

                                        &:hover {
                                            stroke: $sec-design-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .card-3-container {
                .card-3 {
                    background: $dark-mode-color1;
                    // border: 1px solid $dark-mode-color1;

                    &:hover {
                        -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                        -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                    }

                    .info-of-company-outside-container {
                        p {
                            svg path {
                                stroke: $dark-mode-color3;
                            }
                        }
                    }

                    .company-contact-buttons {
                        button {
                            background: $dark-mode-background;
                        }
                    }

                    hr {
                        border-color: $dark-mode-background;
                    }

                    .rating-stars {
                        i {
                            color: $dark-mode-color3;
                        }

                        i.active {
                            color: orange;
                        }
                    }
                }
            }

            .card-4-container {
                width: 100% !important;

                .card-4 {
                    background: $dark-mode-color1;
                    border-color: transparent;

                    &:hover {
                        .walker-name {
                            color: black;
                            background: $sec-design-color;
                        }
                    }

                    div.walker-img {
                        background: transparent !important;
                    }

                    .walker-name {
                        background: $dark-mode-background;
                    }

                    p {
                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }

                    .rating-stars {
                        i {
                            color: $dark-mode-color3;
                        }
                    }
                }
            }

            .vip-post-container {
                .vip-post-container-header {
                    span {
                        background: $sec-design-color;
                    }

                    p {
                        color: $dark-mode-color3;
                    }

                    .next-prev {
                        svg {
                            &:hover {
                                path {
                                    stroke: $dark-mode-color3;
                                }
                            }
                        }
                    }
                }

                .vip-inside-cards-container {
                    .card {
                        background: $dark-mode-color1;
                    }
                }
            }

            .missing-post-main-container {
                .missing-card-container {
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }
                }
            }

            .founded-post-main-container {
                .founded-card-container {
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }
                }
            }

            .cards-menu-for-mainpage {
                .cards-menu-inside-container {
                    .card-for-menu {
                        background: $dark-mode-color4;
                        border: 1px solid $dark-mode-color4;
                    }
                }
            }

            .recently-added-container {
                .recently-added-inside-container {
                    .card {
                        background: $dark-mode-color1;
                    }
                }
            }

            .left-container-posts-header {
                svg {
                    path {
                        fill: $dark-mode-color3;
                    }
                }

                p {
                    color: $dark-mode-color3;
                }
            }
        }

        .right-container {
            .right-container-posts {
                .right-post-inside-container {
                    .right-post-header {
                        p {
                            color: $dark-mode-color3;
                        }
                    }
                }
            }
        }
    }

    .post-page-main-container {
        background: transparent;

        .post-page-header {
            span {
                svg {
                    path {
                        stroke: $dark-mode-color3;
                    }

                    &:hover path {
                        fill: $sec-design-color;
                        stroke: $sec-design-color;
                    }
                }

                svg.active path {
                    fill: $sec-design-color;
                    stroke: $sec-design-color;
                }
            }

            .modalbox-for-links {
                background: $dark-mode-color1;
                -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
                box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

                p {
                    &:hover {
                        background: $dark-mode-background;
                    }
                }
            }
        }

        .post-img-info-container {
            // .seller-info-container {
            //     background: $dark-mode-color1;
            //     border-color: transparent;
            //     box-shadow: none;

            //     .price {
            //         .currency {
            //             .currency-switch {
            //                 svg path {
            //                     fill: $dark-mode-color3;
            //                 }
            //             }

            //             .active {
            //                 background: $dark-mode-background;

            //                 svg {
            //                     path {
            //                         fill: $dark-mode-color3;
            //                     }
            //                 }
            //             }
            //         }
            //     }

            //     hr {
            //         background: $dark-mode-background;
            //         border: 2px solid $dark-mode-background;
            //     }

            //     .seller-phone {
            //         border-color: $dark-mode-background;

            //         svg path {
            //             stroke: $dark-mode-color3;
            //             // fill: $dark-mode-color3;
            //         }

            //         p {
            //             color: $dark-mode-color3;
            //         }

            //         &:hover {
            //             background: $dark-mode-background;
            //         }
            //     }

            //     .send-message {
            //         border-color: $dark-mode-background;

            //         &:hover {
            //             background: $dark-mode-background;
            //             border-color: $dark-mode-background;

            //             svg path {
            //                 stroke: $dark-mode-color3;
            //             }
            //         }

            //         svg path {
            //             stroke: $dark-mode-color3;
            //         }
            //     }
            // }

            .seller-info-container-static {
                background: $dark-mode-color1;
                box-shadow: none;

                .seller-phone-message-outside-container {
                    .seller-phone {
                        p {
                            color: $dark-mode-color3;
                        }

                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }

                    .send-message {
                        p {
                            color: $dark-mode-color3;
                        }

                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }
                }
            }

            .post-description-and-info-main-container {
                .post-description-container {
                    background: $dark-mode-color1;
                    border-color: transparent;
                    box-shadow: none;

                    P {
                        border-bottom: 2px solid $dark-mode-background;
                    }
                }

                .post-pet-info-container {
                    background: $dark-mode-color1;
                    border-color: transparent;
                    box-shadow: none;

                    .post-pet-head {
                        p {
                            border-bottom: 2px solid $dark-mode-background;
                        }
                    }

                    .post-pet-info {
                        label {
                            &:hover {
                                background: transparent;
                            }

                            p {
                                color: $dark-mode-color3;
                            }
                        }
                    }
                }
            }
        }

        .single-post-additional-container {
            background: transparent;

            .single-post-additional-container-header {
                .next-prev {
                    svg {
                        &:hover {
                            path {
                                stroke: $dark-mode-color3;
                            }
                        }
                    }
                }
            }
            .similar-posts-container {
                &::-webkit-scrollbar-track {
                    background: $dark-mode-color1;
                }

                &::-webkit-scrollbar-thumb {
                    background: $sec-design-color;
                }

                .card {
                    background: $dark-mode-color1;

                    &:hover {
                        background: $dark-mode-color1;
                    }
                }
            }
        }
    }

    .order-collars-container {
        background: $dark-mode-color1;

        .collar-order-modal-container {
            .slideshow-and-imglist-and-description {
                .collar-description-block-container {
                    .inside-block-1,
                    .inside-block-2,
                    .inside-block-3,
                    .inside-block-4 {
                        p.title {
                            svg path {
                                fill: white;
                            }
                        }
                    }
                }
            }

            .collar-header-and-options-container {
                .select-color-container {
                    background: $dark-mode-background;
                }

                .collar-quantity-and-price {
                    background: $dark-mode-background;
                }

                .qr-tag-size {
                    div {
                        background: $dark-mode-background;
                    }
                }

                form {
                    .input-container {
                        input {
                            background: $dark-mode-background;

                            &:hover {
                                border-color: $dark-mode-color1;
                            }
                        }

                        hr {
                            border-color: $dark-mode-background;
                        }

                        .pets-info-for-qr {
                            .pet-photo-container {
                                .pet-photo {
                                    background: $dark-mode-background;
                                    background-image: url("../../public/img/photo-camera-white.svg");
                                    background-position: center center;
                                    background-size: 30%;
                                    background-repeat: no-repeat;
                                    border-color: grey;

                                    &:hover {
                                        border-color: $sec-design-color;
                                    }
                                }
                            }
                        }

                        textarea.comment-for-qr-tag {
                            background: $dark-mode-background;

                            &::placeholder {
                                color: white;
                            }
                        }
                    }
                }

                .order-button {
                    color: black;
                }

                .shipping {
                    .shipping-header-flex {
                        svg path {
                            fill: $dark-mode-color3;
                        }

                        .not-fill path {
                            stroke: white;
                        }
                    }

                    .inside-container {
                        .delivery-prices {
                            background: $dark-mode-background;
                        }
                    }
                }

                .wrapper {
                    .select-button {
                        background: $dark-mode-background;

                        div {
                            color: $dark-mode-color3;
                        }

                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }

                    .select-menu-dropdown {
                        background: $dark-mode-background;
                        box-shadow: none;

                        .select-search {
                            input {
                                background: $dark-mode-color1;
                                border-color: $dark-mode-color1;

                                &:hover {
                                    border-color: transparent;
                                }
                            }
                        }

                        ul.options {
                            li {
                                color: $dark-mode-color3;

                                &:hover {
                                    color: white;
                                    background: $dark-mode-color1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .rules-parent-container {
        background: $dark-mode-color1;

        .rules-inside-container {
            .editor {
                background: $dark-mode-background;
            }
        }
    }

    .qr-collar-order-container {
        .qr-card {
            background: $dark-mode-color1;
            box-shadow: none;
        }
    }

    .qr-collar-instruction-container {
        background: $dark-mode-color1;
        // background-image: url(../../public/img/ka1.png);
        box-shadow: none;
    }

    button.scroll-up {
        background: $dark-mode-color1;
        border-color: $dark-mode-background;

        &:hover {
            background: $sec-design-color;
        }

        a {
            svg path {
                fill: $dark-mode-color3;
            }
        }
    }

    footer {
        background: transparent;

        ul {
            li {
                span {
                    color: $dark-mode-color3;
                }

                a {
                    color: $dark-mode-color3;
                }

                p {
                    color: $dark-mode-color3;
                }
            }
        }
    }

    .our-social-pages-and-contact-container {
        background: transparent;

        p {
            color: $dark-mode-color3;
        }

        a {
            background: transparent;
            border-color: transparent;

            &:hover {
                background: transparent;
            }

            svg {
                path {
                    fill: $dark-mode-color3;
                }
            }
        }

        .contact-details-outside-container {
            .contact-details-inside-container {
                span {
                    svg {
                        path {
                            stroke: $dark-mode-color3;
                        }
                    }
                }
            }
        }
    }

    .copyright {
        background: transparent;

        p {
            color: $dark-mode-color3;
        }
    }

    .aboutus {
        .aboutus-hr {
            border: 5px solid $dark-mode-color1;
            background: $dark-mode-color1;
        }
    }

    .contact-container {
        background: transparent;

        .contact-info {
            div {
                div.contact-img {
                    background: $dark-mode-color1;

                    &:hover {
                        background: $sec-design-color;
                    }
                }

                div.contact-text {
                    p {
                        color: $dark-mode-color3;
                    }
                }
            }
        }
    }

    .userpanel-container {
        background: transparent;

        .userpanel-left-container {
            .user-info-mini-container2 {
                background: $dark-mode-color1;
                border-color: $dark-mode-color1;
            }

            .left-menu {
                background: transparent;
                border-color: transparent;

                ul {
                    li {
                        &:hover {
                            background: $dark-mode-color1;
                        }

                        a {
                            color: $dark-mode-color3;
                            border-color: $dark-mode-color1;
                        }

                        svg path {
                            stroke: $dark-mode-color3;
                        }
                    }
                }
            }
        }

        .userpanel-screen-main-container {
            background: transparent;

            .personal-info-container {
                form.user-information {
                    background: $dark-mode-color1;

                    .screen-user-photo {
                        .photo {
                            background: $dark-mode-background;
                        }

                        .upload-user-photo-btn {
                            label {
                                background: $sec-design-color;

                                &:hover {
                                    background: $sec-design-color;
                                }
                            }

                            input[type="file"] {
                                display: none;
                            }
                        }
                    }

                    input {
                        background: $dark-mode-background;
                    }

                    input.submit {
                        color: $dark-mode-color3;
                        background: $sec-design-color;

                        &:hover {
                            background: $sec-design-color;
                        }
                    }

                    select {
                        background: $dark-mode-background;

                        option {
                            background: $dark-mode-background;
                        }
                    }
                }

                form.change-password {
                    background: $dark-mode-color1;

                    input[type="password"],
                    [type="submit"] {
                        color: $dark-mode-color3;
                        background: $dark-mode-background;

                        &::placeholder {
                            color: white;
                        }
                    }

                    input.submit {
                        background: $sec-design-color;

                        &:hover {
                            background: $sec-design-color;
                        }
                    }
                }
            }

            .add-post-container {
                form.add-post {
                    .fields-container {
                        .wrapper2 {
                            background: $dark-mode-color1;

                            input[type="number"] {
                                border-color: $dark-mode-background;

                                &:hover {
                                    border-color: $dark-mode-background !important;
                                }

                                &::placeholder {
                                    color: white;
                                }
                            }

                            input[type="number"]:disabled {
                                background: $dark-mode-background;
                                border-color: $dark-mode-background;

                                &::placeholder {
                                    color: grey;
                                }
                            }

                            .currency-disabled {
                                ul.options {
                                    li {
                                        color: rgb(166, 166, 166) !important;
                                        background: #2c3639;
                                        border-color: #2c3639 !important;
                                    }
                                }
                            }

                            .select-button {
                                div {
                                    color: white;
                                }
                            }

                            .select-menu-dropdown {
                                .select-search {
                                    input {
                                        background-color: $dark-mode-background;
                                        background-image: url(../../public/svg/search-white.svg);

                                        &:hover {
                                            border-color: transparent !important;
                                        }

                                        &::placeholder {
                                            color: white;
                                        }
                                    }
                                }

                                ul.options {
                                    background-color: transparent !important;

                                    li {
                                        color: white;
                                        border-color: $dark-mode-background;

                                        &:hover {
                                            background-color: $dark-mode-background !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .upload-post-img {
                        background: transparent;
                        border: 3px dashed $dark-mode-color1;

                        &:hover {
                            border-color: $sec-design-color;
                        }
                    }

                    textarea {
                        background-color: $dark-mode-color1;

                        &::placeholder {
                            color: white;
                        }
                    }
                }
            } // END ADD POST CONTAINER

            .active-post-container {
                .myactive-post-lit-cont {
                    background: $dark-mode-color1;
                    border-color: transparent;

                    .myactive-post-info-container {
                        .myactive-post-info-inside-container {
                            div {
                                p {
                                    color: grey;
                                    background: $dark-mode-background;

                                    a {
                                        background: $dark-mode-background;
                                    }
                                }
                            }
                        }

                        .vip-edit-delete-container {
                            .edit-delete {
                                a.edit-post {
                                    background: $dark-mode-background;
                                }

                                a.delete-post {
                                    color: red;
                                    background: $dark-mode-background;
                                }
                            }
                        }
                    }
                }
            }

            .generate-qr-container {
                .qr-collar-order-buttons-container {
                    button,
                    a {
                        background: $dark-mode-color1 !important;
                    }
                }

                .purchased-collars {
                    .purchased-collars-cards-container {
                        .modal-background {
                            .qr-collar-buttons-instruction-container {
                                background: $dark-mode-background;
                            }

                            .instruction-container-top-buttons {
                                .close-instruction {
                                    background: $dark-mode-color1;

                                    &:hover {
                                        background: $dark-mode-color1;
                                    }

                                    svg path {
                                        fill: white;
                                    }
                                }
                            }

                            .instruction-container {
                                p {
                                    color: whitesmoke;
                                }
                            }
                        }

                        .purchased-collars-card {
                            .pet-img-and-buttons {
                                background: $dark-mode-color1;

                                button.not-active {
                                    svg path {
                                        fill: white;
                                    }
                                }

                                button.not-active-stroke {
                                    svg path {
                                        stroke: white;
                                    }
                                }
                            }

                            .pet-info-outside-container {
                                background: $dark-mode-color1;

                                form {
                                    .pet-info-container {
                                        label {
                                            p,
                                            input {
                                                background: $dark-mode-background;
                                            }

                                            input:not(:disabled) {
                                                color: white;
                                                background: rgba(55, 55, 55, 1);
                                            }
                                        }

                                        textarea {
                                            background: $dark-mode-background;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .favorite-post-container {
                .card {
                    background: $dark-mode-color1;
                }
            }

            .user-message-container {
                background: $dark-mode-color1;
                .user-list-container {
                    background: $dark-mode-background;

                    &::-webkit-scrollbar-track {
                        background: $dark-mode-background;
                    }

                    .user-name {
                        &:hover {
                            background: $dark-mode-color1;
                        }
                    }
                }

                .read-send-message-container {
                    .read-message {
                        background: $dark-mode-background;

                        &::-webkit-scrollbar-track {
                            background: $dark-mode-background;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: $sec-design-color-dark;
                        }

                        .me {
                            color: $primary-color;
                        }

                        .other-user {
                            background: $dark-mode-color1;
                        }
                    }

                    textarea {
                        background: $dark-mode-background;
                        border-top-color: $dark-mode-color1;

                        &::placeholder {
                            color: $dark-mode-color3;
                        }
                    }

                    button {
                        background: $dark-mode-background;

                        &:hover {
                            background: $sec-design-color;
                        }
                    }
                }
            }
        }
    }

    .logo-and-language-switcher-container {
        .close_login,
        .close_registration {
            color: whitesmoke;
            background: $dark-mode-color1;

            svg path {
                stroke: whitesmoke;
            }
        }

        .lang-switcher-container {
            button {
                background: $dark-mode-color1;
            }

            .lang-list-container {
                background: $dark-mode-color1;
                box-shadow: none;

                ul li:hover {
                    background: $dark-mode-background;
                }
            }
        }
    }

    /**** MODAL BOXES ****/
    .login-container {
        background: $dark-mode-background;
        box-shadow: none;

        .close_login {
            color: $dark-mode-color3;

            &:hover {
                color: $sec-design-color;
            }
        }

        label {
            input[type="email"],
            input[type="password"] {
                background: $dark-mode-color1 !important;
                // border: 1px solid $dark-mode-color1;
                outline: none;

                // &:hover {
                //     border: 1px solid grey;
                // }

                &::placeholder {
                    color: $dark-mode-color3;
                }
            }

            .visible-notvisible {
                svg path {
                    stroke: $dark-mode-color3;
                }
            }
        }

        .error-text,
        .server-error-text {
            color: $sec-design-color;

            svg path {
                fill: $sec-design-color;
            }
        }

        input[type="submit"] {
            // background: $dark-mode-color1;

            &:hover {
                background: $sec-design-color;
                color: $primary-color;
            }
        }

        .social-login-container {
            button {
                color: $dark-mode-color3;
                background: $dark-mode-color1;
            }
        }

        .create-account {
            span {
                &:hover {
                    color: $sec-design-color !important;
                }
            }
        }
    }

    .recover-password {
        background: $dark-mode-color1;

        .close_recover_password {
            color: $dark-mode-color3;

            &:hover {
                color: $sec-design-color;
            }
        }

        input {
            background: $dark-mode-background;
            border: 1px solid $dark-mode-background;
            outline: none;

            &:hover {
                border: 1px solid $dark-mode-background;
            }

            &::placeholder {
                color: $dark-mode-color3;
            }
        }

        input[type="submit"] {
            &:hover {
                color: $primary-color;
                background: $sec-design-color;
            }
        }
    }

    .email-reset-password {
        background: $dark-mode-color1;

        form {
            input {
                color: $dark-mode-color3;
                background: $dark-mode-background;
                border-color: $dark-mode-background;

                &::placeholder {
                    color: $dark-mode-color3;
                }
            }
        }
    }

    .registration-container {
        background: $dark-mode-background;
        box-shadow: none;

        form {
            label {
                p {
                    color: $dark-mode-color3;
                }

                .error-text {
                    color: $sec-design-color;
                    background: transparent;

                    svg path {
                        fill: $sec-design-color;
                    }
                }

                .error-text:before {
                    border-bottom: 20px solid $dark-mode-color4;
                }

                input[type="name"],
                input[type="lastname"],
                input[type="password"],
                input[type="email"],
                input[type="tel"],
                input[type="address"] {
                    background: $dark-mode-color1;
                    outline: none;

                    &::placeholder {
                        color: $dark-mode-color3;
                    }

                    &:hover {
                        border-color: grey;
                    }
                }

                select {
                    background: $dark-mode-color1;
                    outline: none;

                    &:hover {
                        border-color: grey;
                    }
                }
            }

            .registration-text {
                &:hover {
                    a {
                        color: $sec-design-color !important;
                        text-decoration: underline;
                    }
                }

                a {
                    color: $sec-design-color !important;
                    text-decoration: underline;
                }
            }

            input[type="submit"] {
                // background: $dark-mode-color1;

                &:hover {
                    color: $primary-color;
                    background: $sec-design-color;
                }
            }
        }

        .existing-account {
            span {
                color: $sec-design-color !important;

                &:hover {
                    color: $sec-design-color !important;
                }
            }
        }
    }

    .collar-order-modal-container {
        background: $dark-mode-background;

        .slideshow-and-imglist {
            background: $third-color;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;
        }

        .collar-header-and-options-container {
            .warning {
                svg path {
                    fill: $dark-mode-color3;
                }

                p {
                    color: $dark-mode-color3;
                }
            }

            form {
                .input-container {
                    input {
                        color: $dark-mode-color3;
                        background: $dark-mode-color1;
                        border-color: $dark-mode-color1;

                        &::placeholder {
                            color: $dark-mode-color3;
                        }
                    }
                }
            }

            .wrapper {
                .select-button {
                    background: $dark-mode-color1;
                    border-color: $dark-mode-color1;

                    &::placeholder {
                        color: $dark-mode-color3;
                    }

                    div {
                        color: $dark-mode-color3;
                    }

                    svg path {
                        stroke: $dark-mode-color3;
                    }
                }

                .select-menu-dropdown {
                    background: $dark-mode-color1;

                    ul.options {
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: $dark-mode-background;
                        }

                        li {
                            color: $dark-mode-color3;

                            &:hover {
                                color: $sec-design-color;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    /**** END MODAL BOXES ****/

    .find-pet-info-container {
        .find-pet-info-container-body {
            .find-pet-info-outside-container {
                .find-pet-info {
                    * {
                        color: black;
                    }
                }
            }
        }
    }
} // end dark mode theme
