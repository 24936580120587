@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

// for large desktops
@media screen and (min-width: 1280px) {
    body {
        // background-color: lightblue;
    }

    .qr-tags-container {
        max-width: 1190px;
        // padding: 50px;
        border: 2px solid #fcdcbc;
        border-radius: 48px;
        -webkit-border-radius: 48px;
        -moz-border-radius: 48px;
        -ms-border-radius: 48px;
        -o-border-radius: 48px;

        .qr-tags-container-overlay {
            padding: 30px;
        }
    }

    .qr-tag-card {
        max-height: 350px;

        .qr-tag-card-imgbox {
            height: 200px;
            img {
                object-fit: cover;
            }
        }
    }

    .userpanel-container {
        .userpanel-screen-main-container {
            .generate-qr-container {
                .purchased-collars {
                    .purchased-collars-cards-container {
                        grid-template-columns: repeat(2, 1fr);
                        gap: 10px;

                        .purchased-collars-card {
                            // max-width: 100%;

                            .pet-img-and-buttons {
                                .img-box {
                                    width: 175px;
                                    height: 175px;
                                    min-width: 175px;
                                    min-height: 175px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
