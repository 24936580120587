@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.aboutus {
    width: 100%;
    max-width: 1200px !important;
    margin: auto;
    margin-bottom: 25px;
    padding: 15px;
    border: 1px solid red;

    .aboutus-information-container {
        width: 100%;

        .aboutus-information-box-1 {
            // display: flex;
            // justify-content: space-between;
            // align-items: flex-start;
            // gap: 20px;
            width: 100%;
            border: 1px solid green;

            img {
                float: left;
                // width: 100%;
                height: 200px;
                clip-path: circle(3% 64%, 24% 82%, 58% 86%, 75% 49%, 99% 36%, 65% 16%, 12% 12%, 21% 40%);
            }

            .float-right {
                float: right;
            }

            .clipped {
                clip-path: url(#svgPath);
            }

            p {
                width: 100%;
                text-align: justify;
            }
        }

        .box-2 {
            // flex-direction: row-reverse;
        }
    }
}
