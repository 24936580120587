@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
// @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500;600&display=swap');

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 18vh;
    height: 13vh;
    margin: auto;
    z-index: 100000;
    background-image: url("../../public/img/loading.gif") no-repeat center red;
    background-size: 7%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);

    img {
        width: 100%;
        height: 100%;
    }
}

.account-activation-status {
    width: 100%;
    margin: 30vh auto;
    text-align: center;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    svg {
        width: 4vh;
        height: 4vh;
    }

    svg.success path {
        stroke: green;
    }

    svg.failed path {
        stroke: red;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3vh;
        font-size: 3.5vh;
        text-align: center;
    }

    p.success {
        color: green;
    }

    p.failed {
        color: red;
    }
}

.custom-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: $sec-design-color;
    animation: s5 1s infinite linear;
    -webkit-animation: s5 1s infinite linear;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
.custom-loader::before,
.custom-loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: s5 2s infinite;
}
.custom-loader::after {
    margin: 8px;
    animation-duration: 3s;
}

@keyframes s5 {
    100% {
        transform: rotate(1turn);
    }
}
