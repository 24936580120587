@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
.center {
    margin: auto;
    width: 100%;
    padding: 10px;
}

.max-height {
    height: 100% !important;
}

.none {
    display: none !important;
}

.empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    // background: white;
    // border-radius: $secondary-border-radius;
    // -webkit-border-radius: $secondary-border-radius;
    // -moz-border-radius: $secondary-border-radius;
    // -ms-border-radius: $secondary-border-radius;
    // -o-border-radius: $secondary-border-radius;
    img {
        width: 220px;
        height: 110px;
    }
    p {
        color: darkgrey;
        font-size: 1rem;
    }
}

.userpanel-container {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    width: calc(100% - 50px);
    max-width: 1100px;
    margin: 10px auto 40px auto;
    padding: 0;
    background-color: transparent !important;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;
    .userpanel-left-container {
        position: -webkit-sticky;
        position: sticky;
        top: 120px;
        height: 100%;
        border: none;
        .user-info-mini-container2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            padding: 3.5px 7px;
            margin: auto;
            cursor: default;
            // border: 2px solid $hover-color;
            background: var(--bodyback);
            margin-bottom: 7px;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;
            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            a.user-fullname {
                width: 190px;
                font-size: 0.87rem;
                text-decoration: none;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0 7px 0 14px;
                line-height: 1.7;
                cursor: default;
                span {
                    font-size: 0.8rem;
                    color: grey;
                }
            }
            img {
                width: 42px;
                height: 42px;
                object-fit: cover;
                border-radius: $primary-border-radius;
                -webkit-border-radius: $primary-border-radius;
                -moz-border-radius: $primary-border-radius;
                -ms-border-radius: $primary-border-radius;
                -o-border-radius: $primary-border-radius;
            }
        }
        .left-menu {
            height: 100%;
            text-align: left;
            background: transparent;
            margin-top: 7px;
            border: none;
            border-radius: $secondary-border-radius;
            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            ul {
                border-radius: $secondary-border-radius;
                li {
                    list-style: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 23px;
                    border: none;
                    border-radius: $secondary-border-radius;
                    &:hover {
                        background-color: $hover-color;
                    }
                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 14px;
                        white-space: nowrap;
                        font-size: 0.8rem;
                        padding: 15px 0;
                        width: 100%;
                        text-decoration: none;
                        border-bottom: 1px solid $hover-color;
                        svg {
                            width: 21px;
                            height: 21px;
                        }
                    }
                }
            }
        }
    }
    .userpanel-screen-main-container {
        display: flex;
        flex-grow: 1;
        max-height: 100%;
        padding: 0;
        background-color: transparent;
        border-radius: $secondary-border-radius;
        .personal-info-container {
            width: 100%;
            height: 100%;
            margin: auto;
            .personal-info-header-container {
                font-size: 1.2rem;
                padding: 14px 3.5px;
            }
            form.user-information {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                // gap: 35px;
                width: 100%;
                margin: auto;
                margin-bottom: 35px;
                background: var(--bodyback);
                padding: 21px;
                // border: 1px solid $hover-color;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;
                .screen-user-photo {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                    width: 30%;
                    outline: none;
                    // background: var(--bodyback-grey);

                    .photo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 190px;
                        border: none;
                        overflow: hidden !important;
                        // background: var(--bodyback);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            margin: auto;
                            object-fit: contain;
                            // border-radius: inherit;
                            // -webkit-border-radius: inherit;
                            // -moz-border-radius: inherit;
                            // -ms-border-radius: inherit;
                            // -o-border-radius: inherit;
                        }
                    }
                    .upload-user-photo-btn {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin: auto;
                        margin-top: 27px;
                        background-color: transparent !important;
                        border: none;
                        label {
                            width: 100%;
                            font-size: 0.8rem;
                            white-space: nowrap;
                            text-align: center;
                            color: black;
                            // background: var(--bodyback-grey);
                            border: 1px solid transparent;
                            padding: 15px;
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;
                            &:hover {
                                color: $sec-design-color;
                                // background-color: $last-color;
                                cursor: pointer;
                            }
                        }
                        input[type="file"] {
                            display: none;
                        }
                    }
                }
                .user-information-inside-container {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 14px;
                    width: 100%;
                    .user-update-fields {
                        position: relative;
                        width: 45%;
                        // height: 70px;
                        // background: green;
                        input {
                            width: 100%;
                            padding: 18px;
                            font-size: 0.78rem;
                            outline: none;
                            background: transparent;
                            border: 1px solid lightgray;
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;
                            &:hover {
                                border-color: grey;
                            }
                        }
                        .error-text {
                            position: absolute;
                            top: -10px;
                            left: 12px;
                            //z-index: 1;
                            color: white;
                            font-size: 0.8rem;
                            //white-space: nowrap;
                            padding: 0 7px;
                            background: red;
                            border-radius: 8px;
                            -webkit-border-radius: 8px;
                            -moz-border-radius: 8px;
                            -ms-border-radius: 8px;
                            -o-border-radius: 8px;
                        }
                    }
                    select {
                        width: 100%;
                        padding: 18px;
                        font-size: 0.78rem;
                        outline: none;
                        background: transparent;
                        border: 1px solid lightgray;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        &:hover {
                            border-color: grey;
                        }
                        option {
                            border: none;
                            font-size: 0.78rem;
                        }
                    }
                    input.submit {
                        width: 92%;
                        font-size: 0.8rem;
                        cursor: pointer;
                        margin-top: 25px;
                        padding: 15px;
                        background-color: var(--bodyback-grey);
                        color: black;
                        border: 1px solid transparent;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        &:hover {
                            color: white;
                            background-color: $sec-design-color;
                        }
                    }
                }
            }
            form.change-password {
                display: flex;
                justify-content: center;
                align-items: center;
                // flex-wrap: wrap;
                gap: 21px;
                width: 100%;
                padding: 20px 15px;
                margin: auto;
                background: var(--bodyback);
                // border: 1px solid $hover-color;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                .user-update-fields {
                    position: relative;

                    input[type="password"],
                    [type="submit"] {
                        display: block;
                        width: 30%;
                        padding: 15px;
                        font-size: 0.8rem;
                        outline: none;
                        background: transparent;
                        border: 1px solid lightgray;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        &::placeholder {
                            color: grey;
                        }
                        &:hover {
                            border-color: grey;
                        }
                    }

                    .error-text {
                        position: absolute;
                        top: -10px;
                        left: 12px;
                        //z-index: 1;
                        color: white;
                        font-size: 0.8rem;
                        //white-space: nowrap;
                        padding: 0 7px;
                        background: red;
                        border-radius: 8px;
                        -webkit-border-radius: 8px;
                        -moz-border-radius: 8px;
                        -ms-border-radius: 8px;
                        -o-border-radius: 8px;
                    }

                    input[type="password"] {
                        width: 100%;
                    }
                }

                input.submit {
                    cursor: pointer;
                    background: var(--bodyback-grey);
                    color: black;
                    padding: 15px 20px;
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    &:hover {
                        background: $sec-design-color;
                        color: white;
                    }
                }
            }
        } // END PERSONAL INFO CONTAINER
        .add-post-container {
            display: block;
            width: 100%;
            margin: auto;
            form.add-post {
                .fields-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 28px;
                    .for-currency-price-deal {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 35px;
                    }
                    .wrapper2 {
                        position: relative;
                        width: 100%;
                        padding: 18px 21px;
                        background: var(--bodyback);
                        // border: 1px solid $hover-color;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        .error-text {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 6px;
                            font-size: 0.83rem;
                            padding: 0 1.5px;
                            margin-top: 15px;
                            color: red;
                            background: transparent;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;
                            svg {
                                width: 16px;
                                height: 16px;
                                path {
                                    fill: red;
                                }
                            }
                        }
                        .price-deal {
                            width: fit-content;
                            font-size: 0.8rem;
                            padding: 14px 20px;
                            cursor: pointer;
                            background: var(--bodyback-grey); // rgb(245, 133, 0, 0.3);
                            // border: 1px solid $sec-design-color;
                            border: none;
                            border-radius: 13px;
                            -webkit-border-radius: 13px;
                            -moz-border-radius: 13px;
                            -ms-border-radius: 13px;
                            -o-border-radius: 13px;
                            &:hover {
                                // background: rgb(245, 133, 0, 0.7);
                                border-color: rgb(245, 133, 0, 0.5);
                            }
                        }
                        .price-deal.active {
                            background: rgb(245, 133, 0, 0.7);
                            border-color: rgb(245, 133, 0, 0.5);
                        }
                        .none {
                            div {
                                display: none !important;
                            }
                        }
                        .currency-disabled {
                            user-select: none !important;
                            pointer-events: none !important;
                            padding: 0 !important;
                            margin: 0 !important;
                            ul.options {
                                li {
                                    color: rgb(166, 166, 166) !important;
                                    background: lightgrey;
                                    border-color: lightgray !important;
                                }
                            }
                        }
                        .currency-enable {
                            padding: 0 !important;
                            margin: 0 !important;
                        }
                        .select-button {
                            position: relative;
                            width: 100%;
                            display: flex !important;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0 6px;
                            cursor: pointer;
                            // border-bottom: 1px solid $border-primary-color;
                            //background: rgba(255, 255, 255, 0.9) !important;
                            //box-shadow: var(--shadow);
                            // border: 1px solid $border-primary-color;
                            // border-radius: $secondary-border-radius;
                            // -webkit-border-radius: $secondary-border-radius;
                            // -moz-border-radius: $secondary-border-radius;
                            // -ms-border-radius: $secondary-border-radius;
                            // -o-border-radius: $secondary-border-radius;
                            &:hover,
                            &:focus {
                                outline: none;
                                border-color: grey;
                            }
                            div {
                                color: $primary-color;
                                font-size: 0.95rem;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                max-width: 100%;
                                .required {
                                    font-size: 1rem;
                                    font-weight: 700;
                                    color: $sec-design-color;
                                    margin-left: 5px;
                                }
                            }
                            svg {
                                display: none;
                                width: 12px;
                                height: 12px;
                                path {
                                    stroke: grey;
                                }
                            }
                        }
                        .select-button.active {
                            outline: none;
                            background: transparent !important;
                            border-color: grey;
                            svg {
                                transform: rotate(180deg);
                                -webkit-transform: rotate(180deg);
                                -moz-transform: rotate(180deg);
                                -ms-transform: rotate(180deg);
                                -o-transform: rotate(180deg);
                            }
                            .select-menu-dropdown {
                                display: block;
                            }
                        }
                        .select-menu-dropdown {
                            // position: absolute;
                            // display: none;
                            width: 100%;
                            padding: 0;
                            margin-top: 23px;
                            // z-index: 1000;
                            overflow: hidden;
                            .filter-default-text {
                                width: 100%;
                                font-size: 0.85rem;
                                text-align: center;
                                padding: 35px;
                            }
                            .select-search {
                                width: 100%;
                                margin-bottom: 14px;
                                input {
                                    width: 100%;
                                    padding: 9px 40px;
                                    outline: none;
                                    background-color: var(--bodyback-grey);
                                    background-image: url(../../public/svg/search.svg);
                                    background-repeat: no-repeat;
                                    background-position: 15px center;
                                    background-size: 16px;
                                    text-align: left;
                                    border: 1px solid $hover-color;
                                    border-radius: 10px;
                                    -webkit-border-radius: 10px;
                                    -moz-border-radius: 10px;
                                    -ms-border-radius: 10px;
                                    -o-border-radius: 10px;
                                    &::placeholder {
                                        font-size: 0.8rem;
                                    }
                                }
                            }
                            ul.options {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: wrap;
                                gap: 18px;
                                max-height: 280px;
                                background-color: transparent !important;
                                margin: 0;
                                list-style: none;
                                overflow-y: auto;
                                &::-webkit-scrollbar {
                                    width: 7px;
                                }
                                &::-webkit-scrollbar-track {
                                    background-color: transparent;
                                    border-radius: $primary-border-radius;
                                    -webkit-border-radius: $primary-border-radius;
                                    -moz-border-radius: $primary-border-radius;
                                    -ms-border-radius: $primary-border-radius;
                                    -o-border-radius: $primary-border-radius;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background-color: $sec-design-color;
                                    border-radius: $primary-border-radius;
                                    -webkit-border-radius: $primary-border-radius;
                                    -moz-border-radius: $primary-border-radius;
                                    -ms-border-radius: $primary-border-radius;
                                    -o-border-radius: $primary-border-radius;
                                }
                                li {
                                    color: black;
                                    font-size: 0.8rem;
                                    padding: 12px 20px;
                                    cursor: pointer;
                                    border: 2px solid var(--bodyback-grey);
                                    border-radius: 13px;
                                    -webkit-border-radius: 13px;
                                    -moz-border-radius: 13px;
                                    -ms-border-radius: 13px;
                                    -o-border-radius: 13px;
                                    &:hover {
                                        background-color: $hover-color;
                                    }
                                }

                                li.active {
                                    background: var(--bodyback-grey);
                                }
                            }
                        }
                        input {
                            width: fit-content;
                            font-size: 0.8rem;
                            padding: 12px 20px;
                            outline: none;
                            background-color: transparent;
                            border: 2px solid var(--bodyback-grey);
                            border-radius: 13px;
                            -webkit-border-radius: 13px;
                            -moz-border-radius: 13px;
                            -ms-border-radius: 13px;
                            -o-border-radius: 13px;
                            &:hover {
                                border-color: grey;
                            }
                        }
                        input:disabled {
                            background: lightgrey;
                            border-color: lightgray;
                            &::placeholder {
                                color: rgb(166, 166, 166);
                            }
                        }
                    }
                    .short-label {
                        width: 100% !important;
                    }
                }
                .upload-post-img {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 14px;
                    width: 100%;
                    margin: 21px 0;
                    padding: 10px;
                    background: var(--bodyback-grey);
                    border: 3px dashed $border-primary-color;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    &:hover {
                        border-color: $sec-design-color;
                    }
                    .upload-photo {
                        position: relative;
                        display: none;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 14px;
                        width: 100%;
                        height: 150px;
                        margin: 0;
                        cursor: pointer;
                        // background-color: var(--bodyback-grey);
                        // background-image: url("../../public/svg/add-photo.svg");
                        // background-position: center center;
                        // background-size: 30%;
                        // background-repeat: no-repeat;
                        overflow: hidden;
                        // border: 3px dashed $sec-design-color;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        svg {
                            width: 35px;
                            height: 35px;
                            path {
                                fill: $sec-design-color;
                            }
                        }
                        p {
                            color: $sec-design-color;
                            font-weight: 600;
                        }
                        input[type="file"] {
                            position: absolute;
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                            padding: 11px;
                            margin: 0;
                            background-color: $primary-bg;
                        }
                        &:first-child {
                            display: flex;
                        }
                    }
                    .uploaded-photo-box {
                        position: relative;
                        width: 100%;
                        height: 150px;
                        overflow: hidden;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                        &:hover {
                            .delete-uploaded-photo-hover {
                                display: flex;
                            }
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .delete-uploaded-photo-hover {
                            position: absolute;
                            top: 0;
                            display: none;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            background: rgba(59, 59, 59, 0.6);
                            .delete-uploaded-photo {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 14px;
                                cursor: pointer;
                                background: rgba(162, 162, 162, 0.6);
                                border-radius: $secondary-border-radius;
                                -webkit-border-radius: $secondary-border-radius;
                                -moz-border-radius: $secondary-border-radius;
                                -ms-border-radius: $secondary-border-radius;
                                -o-border-radius: $secondary-border-radius;
                                &:hover {
                                    background: rgba(74, 74, 74, 0.6);
                                }
                                svg {
                                    width: 21px;
                                    height: 21px;
                                    path {
                                        fill: white;
                                    }
                                }
                            }
                        }
                    }
                }
                textarea {
                    display: block;
                    width: 100%;
                    height: 160px;
                    outline: none;
                    margin: auto;
                    padding: 25px;
                    resize: none;
                    font-size: 0.8rem;
                    background-color: var(--bodyback);
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    // &:hover {
                    //     border-color: grey;
                    // }
                }
                input[type="submit"] {
                    display: block;
                    width: 30%;
                    padding: 14px 7px;
                    margin: 0 auto;
                    margin-top: 30px;
                    font-size: 0.8rem;
                    opacity: 0.8;
                    cursor: pointer;
                    background: $sec-design-color;
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        } // END ADD POST CONTAINER
        .active-post-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 14px;
            width: 100%; // 98%;
            height: 100%;
            padding: 0;
            overscroll-behavior: contain;
            .myactive-post-lit-cont {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 160px;
                // overflow: hidden;
                padding: 10px;
                background-color: var(--bodyback);
                // border: 1px solid $hover-color;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;
                &:hover {
                    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    .vip-edit-delete-container {
                        .edit-delete {
                            opacity: 1;
                        }
                    }
                }
                div.act-post-img {
                    width: 170px;
                    height: 100%;
                    min-width: 170px;
                    min-height: 100%;
                    overflow: hidden;
                    background-color: rgba(0, 0, 0, 0.7);
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        margin: auto;
                    }
                }
                .myactive-post-info-container {
                    width: 100%;
                    height: 100%;
                    .myactive-post-info-inside-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: space-between;
                        // gap: 7px;
                        width: 100%;
                        height: 100%;
                        padding: 7px 0;
                        .none {
                            display: none;
                        }
                        ul {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            // gap: 25px;
                            width: 100%;
                            border: none;
                            .bold {
                                font-weight: 600;
                            }
                            li {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 14px;
                                font-size: 0.75rem;
                                color: grey;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                border: none;
                                span {
                                    font-size: 0.75rem;
                                    border-radius: inherit;
                                    -webkit-border-radius: inherit;
                                    -moz-border-radius: inherit;
                                    -ms-border-radius: inherit;
                                    -o-border-radius: inherit;
                                }
                            }
                            span {
                                font-size: 0.75rem;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            button {
                                font-size: 0.8rem;
                                padding: 3px 3.5px;
                                background-color: $sec-design-color;
                                border: none;
                                border-radius: $secondary-border-radius;
                                -webkit-border-radius: $secondary-border-radius;
                                -moz-border-radius: $secondary-border-radius;
                                -ms-border-radius: $secondary-border-radius;
                                -o-border-radius: $secondary-border-radius;
                            }
                        }
                        hr {
                            background: var(--bodyback-grey);
                            border: 1px solid var(--bodyback-grey);
                        }
                    }
                }
                .vip-edit-delete-container {
                    height: 100%;
                    padding: 0;
                    // padding-right: 2.5px;
                    margin: 0;
                    // border: 1px solid red;
                    .edit-delete {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end !important;
                        align-items: center;
                        gap: 14px;
                        height: 100%;
                        padding: 0;
                        opacity: 0;
                        // background: var(--bodyback-grey);
                        border: none;
                        // border-left: 2px solid $hover-color;
                        // border-radius: $primary-border-radius;
                        // -webkit-border-radius: $primary-border-radius;
                        // -moz-border-radius: $primary-border-radius;
                        // -ms-border-radius: $primary-border-radius;
                        // -o-border-radius: $primary-border-radius;
                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 5.5px 0;
                            cursor: pointer;
                            // background-color: $last-color;
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;
                            svg {
                                width: 21px;
                                height: 21px;
                            }
                            .vip-active path {
                                fill: orange;
                            }
                            &:hover {
                                &::before {
                                    position: absolute;
                                    left: 100%;
                                    content: "" attr(name) "";
                                    width: fit-content;
                                    white-space: nowrap;
                                    color: black;
                                    font-size: 0.8rem;
                                    font-weight: 500;
                                    padding: 7px;
                                    background: $sec-design-color;
                                    border-radius: $primary-border-radius;
                                    -webkit-border-radius: $primary-border-radius;
                                    -moz-border-radius: $primary-border-radius;
                                    -ms-border-radius: $primary-border-radius;
                                    -o-border-radius: $primary-border-radius;
                                }
                            }
                        }
                    }
                }
            }
        } // END ACTIVE POST CONTAINER
        .generate-qr-container {
            width: 100%;
            height: 100%;
            .qr-collar-order-buttons-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 28px;
                padding: 0;
                margin-bottom: 35px;
                button,
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    padding: 12px 17.5px;
                    text-decoration: none;
                    font-size: 0.78rem;
                    white-space: nowrap;
                    cursor: pointer;
                    background-color: var(--bodyback);
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    &:hover {
                        background-color: $sec-design-color;
                    }
                    svg {
                        width: 17.5px;
                        height: 17.5px;
                    }
                }
            }
            .purchased-collars {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 14px;
                height: 100%;

                .purchased-collars-header {
                    display: none; // flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 14px;
                    width: 100%;
                    padding: 7px;
                    svg {
                        width: 21px;
                        height: 21px;
                    }
                    p {
                        font-size: 1rem;
                    }
                }
                .purchased-collars-cards-container {
                    position: relative;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    align-items: space-between;
                    gap: 21px;
                    row-gap: 21px;
                    width: 100%;
                    height: 100%;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    .modal-background {
                        background: rgba(0, 0, 0, 0.9);

                        .qr-collar-buttons-instruction-container {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            gap: 30px;
                            width: 800px;
                            height: fit-content;
                            padding: 21px;
                            background: rgba(59, 59, 59, 0.6);
                            background: var(--bodyback-grey);
                            transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            -o-transform: translate(-50%, -50%);
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;
                            .instruction-container-top-buttons {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                width: 100%;
                                .close-instruction {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    gap: 9px;
                                    cursor: pointer;
                                    // margin-bottom: 20px;
                                    padding: 7px 14px;
                                    border: 1px solid $hover-color;
                                    border-radius: 13px;
                                    -webkit-border-radius: 13px;
                                    -moz-border-radius: 13px;
                                    -ms-border-radius: 13px;
                                    -o-border-radius: 13px;
                                    &:hover {
                                        background: $sec-design-color;
                                    }
                                    svg {
                                        width: 12px;
                                        height: 12px;
                                    }
                                }
                            }
                            .instruction-container {
                                display: flex;
                                justify-content: center;
                                align-items: flex-start;
                                gap: 20px;
                                padding: 0 7px;
                                p {
                                    font-size: 0.9rem;
                                    color: black;
                                }
                                span {
                                    svg {
                                        width: 20px;
                                        height: 20px;
                                        path {
                                            stroke: white;
                                        }
                                    }
                                }
                                .without-stroke {
                                    svg path {
                                        stroke: transparent;
                                        fill: #26b753;
                                    }
                                }
                                .none-stroke {
                                    svg path {
                                        stroke: transparent;
                                    }
                                }
                                .orange-fill {
                                    svg path {
                                        stroke: $sec-design-color;
                                    }
                                }
                            }
                        }
                    }
                    .purchased-collars-card {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 15px;
                        width: 100%;
                        max-width: 450px;
                        overflow: hidden;
                        //height: 200px;
                        // margin: auto;
                        padding: 25px;
                        background: var(--bodyback);
                        border-radius: 24px;
                        -webkit-border-radius: 24px;
                        -moz-border-radius: 24px;
                        -ms-border-radius: 24px;
                        -o-border-radius: 24px;

                        &:hover {
                            // border-color: $sec-design-color;
                            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            .pet-info-outside-container {
                                display: flex;
                            }
                        }

                        .pet-name-inside-container {
                            display: none;
                            // width: 100%;
                            font-size: 1rem;
                            font-weight: 600;
                            padding: 3px 20px;
                            text-align: center;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            background: var(--bodyback-grey);
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;
                        }

                        .pet-img-and-buttons {
                            position: relative;
                            display: flex;
                            // flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 10px;
                            width: 100%;
                            // height: 100%;
                            min-width: 250px;
                            overflow: hidden;
                            // padding: 0 20px;
                            // background: red;

                            &:hover {
                                .edit-delete-container {
                                    button {
                                        opacity: 1;
                                    }
                                }
                            }

                            .purchased-collars-card-pet-name {
                                display: flex; // flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                // margin: 7px auto;
                                // padding: 7px;
                                // border-top-left-radius: $secondary-border-radius;
                                // border-top-right-radius: $secondary-border-radius;

                                .pet-name-inside-container {
                                    max-width: 90%;
                                    font-size: 1rem;
                                    font-weight: 600;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }
                            .img-box {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 200px;
                                height: 200px;
                                min-width: 200px;
                                min-height: 200px !important;
                                margin: 0 auto;
                                padding: 2px;
                                //overflow: hidden;
                                border: 3px solid var(--bodyback-grey);
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;

                                img.pet-img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    opacity: 1;
                                    background: white;
                                    border-radius: inherit;
                                    -webkit-border-radius: inherit;
                                    -moz-border-radius: inherit;
                                    -ms-border-radius: inherit;
                                    -o-border-radius: inherit;
                                }
                                img.qr-code-png {
                                    // position: absolute;
                                    // top: 0;
                                    // left: 0;
                                    width: 90%;
                                    height: 90%;
                                    object-fit: contain;
                                    background: white;
                                }
                                img.qr-code-png.active {
                                    opacity: 1;
                                }
                            }
                            .edit-delete-container {
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                gap: 21px;
                                width: 100%;
                                height: 100%;
                                padding: 7px 10px;
                                // background: var(--bodyback-grey);
                                border-radius: $secondary-border-radius;
                                -webkit-border-radius: $secondary-border-radius;
                                -moz-border-radius: $secondary-border-radius;
                                -ms-border-radius: $secondary-border-radius;
                                -o-border-radius: $secondary-border-radius;

                                * {
                                    font-family: "BPG Excelsior Caps DejaVu 2010";
                                }

                                button {
                                    display: flex !important;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 10px;
                                    opacity: 1;
                                    white-space: nowrap;
                                    cursor: pointer;
                                    padding: 12px 17px;
                                    background: var(--bodyback-grey);
                                    border: none;
                                    //border-bottom: 2px solid $last-color;
                                    border-radius: $secondary-border-radius;
                                    -webkit-border-radius: $secondary-border-radius;
                                    -moz-border-radius: $secondary-border-radius;
                                    -ms-border-radius: $secondary-border-radius;
                                    -o-border-radius: $secondary-border-radius;
                                    * {
                                        transition: 0.2s ease-in-out;
                                        -webkit-transition: 0.2s ease-in-out;
                                        -moz-transition: 0.2s ease-in-out;
                                        -ms-transition: 0.2s ease-in-out;
                                        -o-transition: 0.2s ease-in-out;
                                    }
                                    span {
                                        display: none;
                                    }
                                    svg {
                                        position: relative;
                                        // top: 3.5px;
                                        width: 18px;
                                        height: 18px;
                                    }
                                    &:hover {
                                        &::before {
                                            display: none !important;
                                            position: absolute;
                                            top: 100%;
                                            content: "" attr(name) "";
                                            width: fit-content;
                                            white-space: nowrap;
                                            font-size: 0.75rem;
                                            font-weight: 500;
                                            padding: 7px;
                                            background: $sec-design-color;
                                            border-radius: $primary-border-radius;
                                            -webkit-border-radius: $primary-border-radius;
                                            -moz-border-radius: $primary-border-radius;
                                            -ms-border-radius: $primary-border-radius;
                                            -o-border-radius: $primary-border-radius;
                                        }
                                    }
                                }
                                .big-icon {
                                    width: 21px;
                                    height: 21px;
                                }
                                button.not-active {
                                    svg path {
                                        fill: grey;
                                    }
                                }
                                button.active {
                                    svg path {
                                        fill: #1aba6b;
                                        // filter: drop-shadow(0px 0px 1px rgba(195, 120, 0, 0.4));
                                        // -webkit-filter: drop-shadow(0px 0px 1px rgba(195, 120, 0, 0.4));
                                    }
                                }
                                button.not-active-stroke {
                                    svg path {
                                        stroke: grey;
                                    }
                                }
                                button.active-stroke {
                                    svg path {
                                        stroke: #1aba6b;
                                        // filter: drop-shadow(0px 0px 1px rgba(212, 131, 2, 0.7));
                                        // -webkit-filter: drop-shadow(0px 0px 1px rgba(212, 131, 2, 0.7));
                                    }
                                }

                                .update-owner-info {
                                    position: relative;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-start;
                                    gap: 21px;
                                    // background: red;

                                    .update-post-btn {
                                        position: absolute;
                                    }
                                }
                            }
                        }
                        .pet-info-outside-container {
                            position: relative;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: space-between;
                            gap: 16px;
                            width: 100%;
                            // padding: 0 20px;
                            // height: 100%;
                            // padding: 21px;
                            // background: green;
                            form {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: space-between;
                                gap: 12px;
                                width: 100%;
                                height: 100%;
                                margin: 0 auto;
                                padding: 0;
                                // background: green;
                                hr {
                                    display: none;
                                    width: 90%;
                                    margin: 0 auto;
                                    background: var(--bodyback-grey);
                                    border: 2px solid var(--bodyback-grey);
                                    border-radius: $secondary-border-radius;
                                    -webkit-border-radius: $secondary-border-radius;
                                    -moz-border-radius: $secondary-border-radius;
                                    -ms-border-radius: $secondary-border-radius;
                                    -o-border-radius: $secondary-border-radius;
                                }
                                .pet-info-top-buttons-container {
                                    // position: absolute;
                                    // top: 9.5px;
                                    // left: 165px;
                                    width: 100%;
                                    display: none; // flex
                                    justify-content: space-between;
                                    align-items: flex-start;
                                    gap: 10px;
                                    margin: 0 auto;
                                    padding: 7px 10px;
                                    background: var(--bodyback-grey);
                                    border-radius: $secondary-border-radius;
                                    -webkit-border-radius: $secondary-border-radius;
                                    -moz-border-radius: $secondary-border-radius;
                                    -ms-border-radius: $secondary-border-radius;
                                    -o-border-radius: $secondary-border-radius;

                                    .pet-info-top-buttons-inside-container {
                                        display: flex;
                                        // flex-direction: column;
                                        justify-content: space-between;
                                        align-items: center;
                                        gap: 10px;
                                    }

                                    button {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 10px;
                                        width: 100%;
                                        padding: 7.5px 10px;
                                        cursor: pointer;
                                        background: transparent;
                                        border: none;
                                        border-radius: $primary-border-radius;
                                        -webkit-border-radius: $primary-border-radius;
                                        -moz-border-radius: $primary-border-radius;
                                        -ms-border-radius: $primary-border-radius;
                                        -o-border-radius: $primary-border-radius;

                                        span {
                                            font-size: 0.78rem;
                                            color: black;
                                        }

                                        svg {
                                            // width: 17px;
                                            height: 16px;
                                            path {
                                                // fill: white;
                                                stroke: grey;
                                            }
                                        }
                                    }
                                    .close-update-container {
                                        display: none; // flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: fit-content;
                                        padding: 7px;
                                        border-radius: 50%;
                                        -webkit-border-radius: 50%;
                                        -moz-border-radius: 50%;
                                        -ms-border-radius: 50%;
                                        -o-border-radius: 50%;
                                        span {
                                            display: none;
                                        }
                                        svg {
                                            width: 15px;
                                            height: 15px;
                                            path {
                                                fill: white;
                                                stroke: transparent;
                                            }
                                        }
                                    }
                                    .update-post-btn {
                                        // position: absolute;
                                        // background: #26b753;
                                    }
                                }
                                .pet-info-container {
                                    display: grid;
                                    grid-template-columns: repeat(1, 1fr);
                                    gap: 15px;
                                    // flex-direction: column;
                                    // justify-content: flex-start;
                                    // align-items: flex-start;
                                    // flex-wrap: wrap;
                                    // flex-direction: column;
                                    // gap: 14px;
                                    width: 100%;
                                    // padding: 7px;
                                    // border-radius: $secondary-border-radius;
                                    // -webkit-border-radius: $secondary-border-radius;
                                    // -moz-border-radius: $secondary-border-radius;
                                    // -ms-border-radius: $secondary-border-radius;
                                    // -o-border-radius: $secondary-border-radius;
                                    .pet-info-container-title {
                                        display: none; // flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        // flex-direction: row-reverse;
                                        gap: 0;
                                        width: 100%;
                                        .title-svg {
                                            display: none; // flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            background: $sec-design-color;
                                            padding: 8.5px;
                                            border-radius: 50%;
                                            -webkit-border-radius: 50%;
                                            -moz-border-radius: 50%;
                                            -ms-border-radius: 50%;
                                            -o-border-radius: 50%;
                                            svg {
                                                width: 16px;
                                                height: 16px;
                                                path {
                                                    fill: white;
                                                    stroke: white;
                                                }
                                            }
                                        }
                                        .title-text {
                                            // display: flex;
                                            position: relative;
                                            width: fit-content;
                                            color: white;
                                            font-size: 0.8rem;
                                            font-weight: 500;
                                            margin: 0 auto;
                                            padding: 8px 14px;
                                            background: $sec-design-color;
                                            border-radius: $third-border-radius;
                                            -webkit-border-radius: $third-border-radius;
                                            -moz-border-radius: $third-border-radius;
                                            -ms-border-radius: $third-border-radius;
                                            -o-border-radius: $third-border-radius;
                                            &:after {
                                                display: none;
                                                content: "";
                                                position: absolute;
                                                top: 0 !important;
                                                left: 0 !important;
                                                width: 25px;
                                                height: 100%;
                                                background-color: var(--bodyback-grey);
                                                border-radius: 50%;
                                                -webkit-border-radius: 50%;
                                                -moz-border-radius: 50%;
                                                -ms-border-radius: 50%;
                                                -o-border-radius: 50%;
                                            }
                                        }
                                    }

                                    span {
                                        width: fit-content;
                                        font-size: 0.85rem;
                                        margin: 10px 20px 0 20px;
                                        // background: red;
                                    }

                                    label {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        // gap: 14px;
                                        width: 100%;
                                        white-space: nowrap;
                                        //text-align: center;
                                        overflow: hidden;
                                        // background: red;
                                        // border-radius: $primary-border-radius;
                                        // -webkit-border-radius: $primary-border-radius;
                                        // -moz-border-radius: $primary-border-radius;
                                        // -ms-border-radius: $primary-border-radius;
                                        // -o-border-radius: $primary-border-radius;
                                        p,
                                        input {
                                            width: 100%;
                                            //max-width: 200px;
                                            text-align: left;
                                            font-size: 0.8rem;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            margin: 0 auto;
                                            padding: 20px;
                                            background: transparent;
                                            border: 2px solid var(--bodyback-grey);
                                            //border-top: 2px solid grey;
                                            //border-bottom: 2px solid var(--bodyback-grey);
                                            border-radius: 24px;
                                            -webkit-border-radius: 24px;
                                            -moz-border-radius: 24px;
                                            -ms-border-radius: 24px;
                                            -o-border-radius: 24px;
                                        }
                                        input:not(:disabled) {
                                            // color: white;
                                            background: rgba(74, 74, 74, 0.1);
                                        }
                                    }
                                    textarea {
                                        grid-column: 1/3;
                                        width: 100%;
                                        height: 100%;
                                        min-height: 70px;
                                        padding: 0 10px;
                                        font-size: 0.8rem;
                                        resize: none;
                                        background: transparent;
                                        border: none;
                                        // border-radius: $primary-border-radius;
                                        // -webkit-border-radius: $primary-border-radius;
                                        // -moz-border-radius: $primary-border-radius;
                                        // -ms-border-radius: $primary-border-radius;
                                        // -o-border-radius: $primary-border-radius;
                                        &::-webkit-scrollbar {
                                            width: 7px;
                                        }
                                        &::-webkit-scrollbar-track {
                                            margin: 5px;
                                            background-color: transparent;
                                            border-radius: $primary-border-radius;
                                            -webkit-border-radius: $primary-border-radius;
                                            -moz-border-radius: $primary-border-radius;
                                            -ms-border-radius: $primary-border-radius;
                                            -o-border-radius: $primary-border-radius;
                                        }
                                        &::-webkit-scrollbar-thumb {
                                            background-color: $last-color;
                                            border-radius: $primary-border-radius;
                                            -webkit-border-radius: $primary-border-radius;
                                            -moz-border-radius: $primary-border-radius;
                                            -ms-border-radius: $primary-border-radius;
                                            -o-border-radius: $primary-border-radius;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } // END GENERATE QR CONTAINER
        .favorite-post-container {
            width: 100%;
            height: 100%;
            .favorite-post-inside-container {
                display: grid;
                // flex-wrap: wrap;
                grid-template-columns: repeat(3, 1fr);
                gap: 21px;
                width: 100%;
                height: fit-content;
                // padding-right: 14px;
                overscroll-behavior: contain;

                .card {
                    // max-height: calc(100vh / 3.3);
                }
            }
        } // END FAVORITE POST CONTAINER
        .user-message-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
            gap: 7px;
            width: 100%;
            height: 490px;
            background: var(--bodyback);
            padding: 21px;
            border: 1px solid $hover-color;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;
            .user-list-container {
                display: flex;
                flex-direction: column;
                gap: 7px;
                width: 30%;
                max-width: 30%;
                height: 100%;
                padding: 7px;
                overflow-y: auto;
                // border: 1px solid $border-primary-color;
                background-color: var(--bodyback-grey);
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                    border-radius: $primary-border-radius;
                    -webkit-border-radius: $primary-border-radius;
                    -moz-border-radius: $primary-border-radius;
                    -ms-border-radius: $primary-border-radius;
                    -o-border-radius: $primary-border-radius;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $last-color;
                    border-radius: $primary-border-radius;
                    -webkit-border-radius: $primary-border-radius;
                    -moz-border-radius: $primary-border-radius;
                    -ms-border-radius: $primary-border-radius;
                    -o-border-radius: $primary-border-radius;
                }
                .user-name {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 14px;
                    padding: 7px;
                    cursor: pointer;
                    border-radius: $primary-border-radius;
                    -webkit-border-radius: $primary-border-radius;
                    -moz-border-radius: $primary-border-radius;
                    -ms-border-radius: $primary-border-radius;
                    -o-border-radius: $primary-border-radius;
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $last-color;
                    }
                    img {
                        width: 13%;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                    }
                    p {
                        font-size: 0.85rem;
                        white-space: nowrap;
                    }
                    span {
                        // position: absolute;
                        // top: -7px;
                        // left: 7px;
                        // padding: 3.5px 7px;
                        font-size: 0.7rem;
                        color: $sec-design-color;
                        // background-color: $sec-design-color;
                        margin: 0 7px;
                        // transform: rotate(10deg);
                        // -webkit-transform: rotate(10deg);
                        // -moz-transform: rotate(10deg);
                        // -ms-transform: rotate(10deg);
                        // -o-transform: rotate(10deg);
                        // border-radius: 50%;
                        // -webkit-border-radius: 50%;
                        // -moz-border-radius: 50%;
                        // -ms-border-radius: 50%;
                        // -o-border-radius: 50%;
                    }
                }
            }
            .read-send-message-container {
                width: 65%;
                height: 100%;
                .read-message {
                    width: 100%;
                    height: 60%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding: 10.5px 7px;
                    background: var(--bodyback-grey);
                    // border-radius: $secondary-border-radius;
                    // -webkit-border-radius: $secondary-border-radius;
                    // -moz-border-radius: $secondary-border-radius;
                    // -ms-border-radius: $secondary-border-radius;
                    // -o-border-radius: $secondary-border-radius;
                    border-top-left-radius: $secondary-border-radius;
                    border-top-right-radius: $secondary-border-radius;
                    &::-webkit-scrollbar {
                        width: 7px;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $last-color;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }
                    .messages-lane {
                        display: inline-block;
                        width: 100% !important;
                        p {
                            width: fit-content;
                            max-width: 50%;
                            padding: 7px;
                            white-space: wrap !important;
                            overflow-wrap: break-word;
                            font-size: 0.8rem;
                            // margin: 3.5px 7px;
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;
                        }
                        .me {
                            float: right;
                            background-color: $sec-design-color;
                        }
                        .other-user {
                            float: left;
                            background-color: $last-color;
                        }
                    }
                }
                textarea {
                    width: 100%;
                    height: 25%;
                    // margin: 14px 0;
                    margin-bottom: 14px;
                    padding: 14px;
                    resize: none;
                    font-size: 0.85rem;
                    outline: none;
                    // border: 1px solid $border-primary-color;
                    border: none;
                    border-top: 3px solid var(--bodyback);
                    background-color: var(--bodyback-grey);
                    // border-radius: $secondary-border-radius;
                    // -webkit-border-radius: $secondary-border-radius;
                    // -moz-border-radius: $secondary-border-radius;
                    // -ms-border-radius: $secondary-border-radius;
                    // -o-border-radius: $secondary-border-radius;
                    border-bottom-left-radius: $secondary-border-radius;
                    border-bottom-right-radius: $secondary-border-radius;
                    &::placeholder {
                        color: $search-font-color;
                        // padding: 0 7px;
                    }
                }
                button {
                    display: block;
                    width: 30%;
                    padding: 10px 7px;
                    margin: auto;
                    font-size: 0.8rem;
                    cursor: pointer;
                    background: $sec-design-color;
                    opacity: 0.9;
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        } // END MESSAGE CONTAINER
    }
}
