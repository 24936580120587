@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.none {
    display: none !important;
}

.post-page-main-container {
    width: calc(100% - 50px);
    max-width: 1100px;
    margin: 30px auto;
    padding: 0;
    background-color: transparent;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    .modal-for-img {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999;
        display: none;
        width: 100%;
        height: 100%;
        background-color: var(--bodyback-grey); // rgba(0, 0, 0, 0.95);

        .full-img-box {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            // max-width: 1200px;
            margin: auto;

            .close-imgbox {
                position: absolute;
                top: 40px;
                right: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 7px;
                color: black; // rgba(237, 237, 237, 0.5);
                float: right;
                font-size: 0.85rem;
                font-weight: bold;
                padding: 7px 20px;
                clear: both;
                background-color: var(--bodyback);
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                &:hover,
                &:focus {
                    // background-color: rgba(237, 237, 237, 0.9);
                    text-decoration: none;
                    cursor: pointer;
                }

                span {
                    color: black; // rgba(237, 237, 237, 0.5);
                    font-size: 1.3rem;
                    font-weight: bold;
                }
            }

            img.modal-img {
                display: block;
                height: 90%;
                margin: auto;
                border: none;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;
            }

            .prev,
            .next {
                cursor: pointer;
                position: absolute;
                top: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                padding: 10px;
                background-color: var(--bodyback);
                border-radius: 50%;
                user-select: none;
                -webkit-user-select: none;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);

                svg {
                    width: 30px;
                    height: 30px;
                }
            }

            /* Position the "next button" to the right */
            .prev {
                left: 50px;
                border-radius: 50%;
            }

            .next {
                right: 50px;
                border-radius: 50%;
            }

            /* On hover, add a black background color with a little bit see-through */
            .prev:hover,
            .next:hover {
                background-color: rgba(237, 237, 237, 0.9);
            }
        }
    }

    .post-page-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 7px;

        p {
            font-size: 1.3rem;
        }

        .post-title-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
        }

        .city-view-id-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 21px;
            padding: 0 9px;
            margin: 7px 0;

            * {
                font-size: 0.78rem;
                font-weight: normal;
            }
        }
    }

    .city-view-id-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 21px;
        font-size: 0.78rem;
        font-weight: normal;
        padding: 0 9px;
        margin: 7px 0;
    }

    .post-img-info-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 7px;

        .right-container-for-banner-in-single-post {
            display: flex;
            justify-content: center;
            align-items: center;
            row-gap: 25px;
            flex-direction: column;
            width: 30%;
            max-width: 30%;
            padding: 0;
            padding-left: 21px;

            img {
                width: 100%;
                height: auto;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;
            }
        }

        .post-img-and-info-container {
            // width: calc(100% - 30%);
            flex: 1;
            max-width: 70%;

            .post-page-img-gallery {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 7px;
                width: 100%;
                margin: 30px 0;
                margin-top: 0;

                .slideshow-container {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    min-height: 600px;
                    max-height: 600px;
                    overflow: hidden;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    .post-status-container {
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        color: white;
                        font-size: 0.9rem;
                        background: $sec-design-color;
                        padding: 2px 10px;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }

                    .header-svg-container {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        padding: 7px; // 8px 15px;
                        background: rgba(237, 237, 237, 0.9);
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        .svg-inside-container {
                            display: flex !important;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            padding: 2.7px;

                            &:hover {
                                .title {
                                    display: block;
                                }
                            }

                            svg {
                                width: 22px;
                                height: 22px;
                                cursor: pointer;

                                path {
                                    stroke-width: 5;
                                    stroke: black;
                                    fill: transparent;
                                }

                                &:hover {
                                    path {
                                        stroke: $sec-design-color;
                                    }
                                }
                            }

                            svg.active {
                                path {
                                    stroke: $sec-design-color;
                                    fill: $sec-design-color;
                                }
                            }

                            svg.share-link {
                                path {
                                    stroke-width: 1;
                                    stroke: rgba(55, 55, 55, 1);
                                }

                                &:hover path {
                                    fill: none;
                                    stroke: $sec-design-color;
                                }
                            }

                            svg.share-link.active {
                                width: 21px;
                                height: 21px;
                                cursor: pointer;

                                path {
                                    stroke: none;
                                    fill: $sec-design-color;
                                }

                                .modalbox-for-links {
                                    display: block;
                                }
                            }

                            .title {
                                display: none;
                                position: absolute;
                                top: 90%;
                                color: white;
                                font-size: 0.8rem;
                                font-weight: normal;
                                white-space: nowrap;
                                padding: 3.5px 10.5px;
                                background-color: $sec-design-color;

                                border-radius: $primary-border-radius;
                                -webkit-border-radius: $primary-border-radius;
                                -moz-border-radius: $primary-border-radius;
                                -ms-border-radius: $primary-border-radius;
                                -o-border-radius: $primary-border-radius;
                            }
                        }
                    }

                    .modalbox-for-links {
                        position: absolute;
                        top: 50px;
                        right: 0;
                        z-index: 4;
                        display: block;
                        width: fit-content;
                        padding: 7px;
                        background-color: $primary-bg;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                        p,
                        a,
                        span {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap !important;
                            gap: 10px;
                            text-wrap: nowrap !important;
                            font-size: 0.75rem;
                            text-decoration: none;
                            padding: 10.5px;
                            cursor: pointer;
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;

                            &:hover {
                                background-color: $sec-design-color;
                                color: white;

                                svg {
                                    path {
                                        stroke: white;
                                    }
                                }
                            }

                            svg {
                                width: 17px;
                                height: 17px;

                                path {
                                    stroke: black;
                                }
                            }
                        }
                    }

                    img {
                        width: 100%;
                        height: 600px;
                        object-fit: cover;
                        cursor: pointer;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                    }

                    .prev,
                    .next {
                        cursor: pointer;
                        position: absolute;
                        top: 50%;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        width: fit-content;
                        padding: 5px;
                        background-color: rgba(0, 0, 0, 0.6);
                        user-select: none;
                        -webkit-user-select: none;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;

                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);

                        svg {
                            width: 35px;
                            height: 35px;

                            path {
                                fill: white;
                            }
                        }
                    }

                    /* Position the "next button" to the right */
                    .prev {
                        left: 30px;
                    }

                    .next {
                        right: 30px;
                    }

                    /* On hover, add a black background color with a little bit see-through */
                    .prev:hover,
                    .next:hover {
                        background-color: rgba(0, 0, 0, 0.8);
                    }
                }

                .img-list-container {
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    max-width: 90%;
                    //height: 490px;
                    overflow-y: auto;
                    display: flex;
                    //flex-direction: column;
                    gap: 4px;
                    padding: 4px;
                    // background: rgba(255, 255, 255, 0.7);
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    img {
                        width: 70px;
                        height: 60px;
                        cursor: pointer;
                        object-fit: cover;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                    }

                    &::-webkit-scrollbar {
                        height: 4px;
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $sec-design-color;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }
                }
            }

            .seller-info-container-static {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                width: 100%;
                padding: 7px;
                margin: 0 0 14px 0;
                background-color: $primary-bg;
                border: 1px solid transparent;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                .price {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 14px;
                    // background: rgba(55, 55, 55, 1);
                    border-radius: 13px;
                    -webkit-border-radius: 13px;
                    -moz-border-radius: 13px;
                    -ms-border-radius: 13px;
                    -o-border-radius: 13px;

                    p {
                        font-size: 1.3rem;
                        font-weight: 500;
                        margin: 0;
                        white-space: nowrap;
                        // padding-right: 14px;
                    }

                    .currency {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 3px;
                        gap: 0;
                        //width: 40%;
                        overflow: hidden;
                        // border: 1px solid red;
                        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;

                        .currency-switch {
                            display: flex !important;
                            justify-content: center;
                            align-items: center;
                            //background: rgb(234, 234, 234);
                            transition: all 0.2s linear;
                            -webkit-transition: all 0.2s linear;
                            -moz-transition: all 0.2s linear;
                            -ms-transition: all 0.2s linear;
                            -o-transition: all 0.2s linear;
                            cursor: pointer;
                            padding: 7px;
                            //border-radius: 50%;
                            //-webkit-border-radius: 50%;
                            //-moz-border-radius: 50%;
                            //-ms-border-radius: 50%;
                            //-o-border-radius: 50%;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;

                            svg {
                                width: 15px;
                                height: 15px;

                                path {
                                    fill: $search-font-color;
                                }
                            }
                        }

                        .active {
                            background-color: $sec-design-color;

                            svg {
                                path {
                                    fill: black;
                                }
                            }
                        }
                    }
                }

                .price-with-deal {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        font-size: 0.95rem;
                        font-weight: 500;
                        margin: 0;
                        padding-right: 14px;
                        white-space: nowrap;
                    }
                }

                .adopt-singlepost,
                .founded-singlepost,
                .missing-singlepost {
                    width: fit-content;
                    padding: 10px 35px;
                    text-align: center;
                    align-self: center;
                    color: $third-color;
                    background-color: $sec-design-color;
                    border-radius: inherit;
                    -webkit-border-radius: inherit;
                    -moz-border-radius: inherit;
                    -ms-border-radius: inherit;
                    -o-border-radius: inherit;

                    p {
                        font-size: 0.87rem;
                        color: $third-color;
                    }
                }

                .founded-singlepost {
                    background-color: #26b753;
                }

                .missing-singlepost {
                    background-color: red;
                }

                .seller-profile {
                    width: fit-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 14px;
                    padding: 5px 7px;
                    margin: 0;
                    background-color: transparent;
                    border-radius: inherit;
                    -webkit-border-radius: inherit;
                    -moz-border-radius: inherit;
                    -ms-border-radius: inherit;
                    -o-border-radius: inherit;

                    .seller-photo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: auto;
                        border-radius: inherit;
                        -webkit-border-radius: inherit;
                        -moz-border-radius: inherit;
                        -ms-border-radius: inherit;
                        -o-border-radius: inherit;

                        img {
                            position: relative;
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;
                        }
                    }

                    .seller-name-and-activeposts {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 1.5px;

                        p {
                            font-size: 0.9rem;
                            font-weight: 500;
                        }

                        a {
                            display: none;
                            font-size: 0.7rem;
                            font-weight: normal;
                            color: grey;
                            text-decoration: none;
                        }
                    }
                }

                .seller-phone-message-outside-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    flex: 1;
                    padding-right: 30px;
                    border-radius: inherit;
                    -webkit-border-radius: inherit;
                    -moz-border-radius: inherit;
                    -ms-border-radius: inherit;
                    -o-border-radius: inherit;

                    .seller-phone {
                        position: relative;
                        width: fit-content;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        gap: 12px;
                        cursor: pointer;
                        padding: 13px 0;
                        overflow: hidden;
                        background: transparent; // #26b753;
                        border: none;
                        border-radius: inherit;
                        -webkit-border-radius: inherit;
                        -moz-border-radius: inherit;
                        -ms-border-radius: inherit;
                        -o-border-radius: inherit;

                        // &:hover {
                        //     background-color: $hover-color;
                        // }

                        svg {
                            width: 18px;
                            height: 18px;

                            path {
                                stroke: $search-font-color;
                            }
                        }

                        p,
                        a {
                            color: black;
                            font-size: 0.87rem;
                            white-space: nowrap;
                        }
                    }

                    .send-message {
                        position: relative;
                        width: fit-content;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        gap: 12px;
                        cursor: pointer;
                        padding: 13px 0;
                        margin: 0;
                        overflow: hidden;
                        background: transparent;
                        border: none;
                        border-radius: inherit;
                        -webkit-border-radius: inherit;
                        -moz-border-radius: inherit;
                        -ms-border-radius: inherit;
                        -o-border-radius: inherit;

                        // &:hover {
                        //     background-color: $hover-color;
                        // }

                        svg {
                            width: 18px;
                            height: 18px;

                            path {
                                stroke: $search-font-color;
                            }
                        }

                        p {
                            font-size: 0.87rem;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .post-description-and-info-main-container {
                display: block;
                justify-content: space-between;
                align-items: flex-start;

                .post-description-container {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 14px;
                    background-color: $primary-bg;
                    border: 1px solid transparent;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                    p {
                        font-size: 1rem;
                        padding: 14px 21px;
                        border: none;
                        border-bottom: 2px solid $back-new-color;
                    }

                    .post-description {
                        font-size: 0.85rem;
                        padding: 21px;
                        white-space: pre-wrap; /* CSS3 */
                        white-space: -moz-pre-wrap; /* Firefox */
                        white-space: -pre-wrap; /* Opera <7 */
                        white-space: -o-pre-wrap; /* Opera 7 */
                        word-wrap: break-word;
                    }
                }

                .sticky-banner-container2 {
                    position: relative;
                    width: 100%;
                    height: 140px;
                    margin: 0;
                    cursor: pointer;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                    }
                }

                .post-pet-info-container {
                    width: 100%;
                    margin: 0;
                    margin: 14px 0;
                    background-color: $primary-bg;
                    border: 1px solid transparent;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                    .post-pet-head {
                        p {
                            font-size: 1rem;
                            padding: 14px 21px;
                            border: none;
                            border-bottom: 2px solid $back-new-color;
                        }
                    }

                    .post-pet-info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 50px;
                        row-gap: 50px;
                        flex-wrap: wrap;
                        padding: 25px;

                        label {
                            // display: flex;
                            // justify-content: flex-start;
                            // align-items: center;
                            // gap: 35px;
                            // padding: 7px 14px;
                            // border-radius: $secondary-border-radius;
                            // -webkit-border-radius: $secondary-border-radius;
                            // -moz-border-radius: $secondary-border-radius;
                            // -ms-border-radius: $secondary-border-radius;
                            // -o-border-radius: $secondary-border-radius;

                            &:hover {
                                // background: linear-gradient(90deg, rgba(153,204,102,1) 0%, rgba(228,231,237,1) 100%);
                                // background: linear-gradient(90deg, rgba(218,221,226,1) 0%, rgba(228,231,237,1) 100%);
                            }

                            .p1 {
                                // display: none;
                                width: 150px;
                                font-size: 0.8rem;
                                font-weight: bold;
                                padding: 0;
                                margin-bottom: 10px;
                            }

                            .p2 {
                                color: grey;
                                font-size: 0.78rem;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .single-post-additional-container {
        // padding: 1vh;
        margin: 70px 7px 0 7px;
        // background-color: $back-new-color;
        border-radius: $secondary-border-radius;

        .single-post-additional-container-header {
            display: flex !important;
            justify-content: space-between;
            align-items: center !important;
            padding: 14px 7px;
            font-size: 1.1rem;

            .next-prev {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 7px;

                svg {
                    width: 28px;
                    height: 28px;
                    cursor: pointer;

                    path {
                        stroke: grey;
                    }

                    &:hover {
                        path {
                            stroke: rgb(48, 48, 48);
                        }
                    }
                }
            }
        }

        .similar-posts-container {
            width: 100%;

            .card {
                width: 100%;
            }
        }
    }
}
