$primary-fontsize: 1.6vh;
$primary-fontweight: normal;
$secondary-fontsize: 1.8vh;
$secondary-fontweight: bold;
$third-fontsize: 2.5vh;
$filter-fontsize: 1.8vh;

$back-new-color: #f5f5f5;
$primary-bg: white;
$secondary-bg: rgba(255, 255, 255, 0.1);

$primary-color: black;
$secondary-color: #e43a15; // #FA7E26
$third-color: white;
$sec-design-color: #ff6500; // #f68424; // #66ab67; // #f58500ff; // #194172; // #f47650; // #fc6719; // #335b39; // #b4d984; // #99cc66; // #FFAA33 ; // #99C;
$fourth-color: #f7f8fa;
$last-color: #e4e7ed;
$secondary-green: rgba(239, 245, 239, 1); // rgb(255, 201, 93); // rgba(239, 245, 239, 1);
$third-green: rgba(245, 245, 239, 0.4);
$hover-color: rgba(75, 75, 75, 0.1);

$primary-border-radius: 10px;
$secondary-border-radius: 16px;
$third-border-radius: 60px;
$border-primary-color: lightgray;
$border-secondary-color: gray;

$box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
$box-shadow2: 0px 4px 5px 0px rgba(255, 255, 255, 0.1);
$box-shadow-inset:
    inset 1px 1px 7px 1px rgba(0, 0, 0, 0.75),
    inset -1px -1px 7px 1px rgba(0, 0, 0, 0.75);

$primary-transition: all 0.4s;

$search-font-size: 1.7vh;
$search-font-color: #5e5e5e;
$search-font-color2: #5e697d;

$dark-mode-background: #2c3639; // #343f4a;
$dark-mode-color1: #3f4e4f; // #5e697d;
$sec-design-color-dark: #e4e7ed; // #DCD7C9;
$dark-mode-color2: #5e5e5e;
$dark-mode-color3: #e4e7ed;
$dark-mode-color4: #535c6d;
