@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
// for tablets
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
    * {
        padding: 0;
        margin: 0;
    }
    .flex {
        flex-direction: column;
    }
    .breeds-dropdown-size {
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        ul.options {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            scroll-behavior: contain !important;
        }
    }
    .city-dropdown-size {
        left: 50%;
        width: 600px !important;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        ul.options {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            scroll-behavior: contain !important;
        }
    }
    .parent-container-short {
        // padding: 0 14px;
        width: calc(100% - 0px);
        max-width: 100%;
    }
    .full-site-parent-container,
    .footer-parent-container {
        // width: 100%;
        // padding: 0 20px !important;
    }
    .header-middle-parent-container {
        .header-middle-inside-container {
            // gap: 27px;
            // padding: 20px 30px;
            .header-middle-short-menu {
                display: none;
            }
            // .header-right-container {
            //     gap: 21px;
            //     // padding-right: 10px;
            //     // border: 1px solid red;
            //     .menu-for-mobile {
            //         display: none !important;
            //     }
            //     .login-signup-container {
            //         button {
            //             padding: 0;
            //             background: transparent !important;
            //             border-color: transparent;
            //             border-radius: 50%;
            //             -webkit-border-radius: 50%;
            //             -moz-border-radius: 50%;
            //             -ms-border-radius: 50%;
            //             -o-border-radius: 50%;
            //             &:hover {
            //                 border-color: transparent;
            //             }
            //             svg {
            //                 height: 25px;
            //                 max-height: 25px;
            //                 padding: 0 !important;
            //                 background: transparent !important;
            //                 path {
            //                     fill: black;
            //                     stroke: black;
            //                 }
            //             }
            //             p {
            //                 display: none;
            //             }
            //         }
            //     }
            //     .user-head-container {
            //         border-radius: 50%;
            //         -webkit-border-radius: 50%;
            //         -moz-border-radius: 50%;
            //         -ms-border-radius: 50%;
            //         -o-border-radius: 50%;
            //         button {
            //             padding: 0;
            //             background: transparent;
            //             border-color: transparent;
            //             &:hover {
            //                 border-color: transparent;
            //             }
            //             svg {
            //                 width: 25px;
            //                 max-height: 25px;
            //             }
            //             a.user-fullname {
            //                 display: none;
            //             }
            //         }
            //         .user-dropdown-container {
            //             left: auto;
            //             right: -70px !important;
            //         }
            //     }
            //     .language-and-darkmode {
            //         gap: 21px;
            //         padding: 0;
            //         .lang-switcher-container {
            //             border-radius: 50%;
            //             -webkit-border-radius: 50%;
            //             -moz-border-radius: 50%;
            //             -ms-border-radius: 50%;
            //             -o-border-radius: 50%;
            //             button {
            //                 padding: 0;
            //                 background: transparent;
            //                 border-color: transparent;
            //                 border-radius: 0;
            //                 -webkit-border-radius: 0;
            //                 -moz-border-radius: 0;
            //                 -ms-border-radius: 0;
            //                 -o-border-radius: 0;
            //                 &:hover {
            //                     border-color: transparent;
            //                 }
            //                 img {
            //                     height: 23px;
            //                     max-height: 23px;
            //                 }
            //                 span {
            //                     display: none;
            //                 }
            //             }
            //         }
            //         .lang-list-container {
            //             left: auto !important;
            //             right: 0 !important;
            //             transform: none !important;
            //             -webkit-transform: none !important;
            //             -moz-transform: none !important;
            //             -ms-transform: none !important;
            //             -o-transform: none !important;
            //         }
            //         .dark-mode-btn {
            //             padding: 0;
            //             background: transparent;
            //             border-color: transparent;
            //             &:hover {
            //                 border-color: transparent;
            //             }
            //             svg {
            //                 height: 25px;
            //                 max-height: 25px;
            //             }
            //         }
            //     }
            // }
        }
    }
    .head-nav {
        display: flex;
        margin: 0 !important;

        .head-nav-inside-container {
            justify-content: flex-start;
            gap: 30px;
            // max-width: 1000px;
            // padding: 0 30px;
            margin: auto;
            background: var(--bodyback);
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;

            a {
                color: black;
            }

            .service-for-large-desktop {
                display: none;
            }

            div.institutions-dropdown-button-container {
                display: none; // flex;

                .institutions-dropdown-container {
                    padding: 7px 0;
                }
            }
        }
    }
    .qr-tags-swiper {
        // margin-top: 20px;
        height: fit-content !important;
        max-height: fit-content !important;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
            right: 0;
        }
        .swiper-button-prev {
            left: 0;
        }
    }
    .qr-tags-container {
        height: 100% !important;
        padding: 30px 0 !important;
        // padding: 10px 20px !important;
        margin-top: 0;
        border: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        .qr-tags-container-overlay {
            .text-for-qr-tags-container {
                // flex-direction: column;
                // flex-direction: column-reverse;
                // justify-content: flex-start;
                // align-items: center !important;
                // gap: 10px;
                padding: 0 !important;
                // padding: 15px !important;
                .right-face-img {
                    width: 50%;
                    max-width: 50%;
                }
                .qr-tag-main-text {
                    p {
                        font-size: 1.1rem; // 2.3rem;
                        .priority {
                            font-size: 1.1rem;
                        }
                    }
                    .pethub_app_text {
                        display: none;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
    .qr-tag-card {
        max-height: 350px;

        .qr-tag-card-imgbox {
            height: 200px;
            img {
                object-fit: cover;
            }
        }
    }

    .qr-tag-list-container {
        width: calc(100% - 50px);
    }

    .custom-qr-tag-by-user-parent-container {
        width: 100%;
        flex-direction: column;
        gap: 0;
        margin: 0 auto;
        background: #f3e5dc;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        .custom-qr-tag-by-user {
        }
        .custom-qr-tag-by-user-text {
            max-width: 100%;
            text-align: center;
            // margin-top: 30px;

            p {
                font-size: 1.8rem;
            }

            a {
                // display: none;
                margin-bottom: 30px;
            }
        }
    }
    .qr-and-gps-tags {
        .qr-and-gps-tags-inside-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            .tag-container {
                width: 45%;
            }
        }
    }
    .tags-card-container-for-homepage {
        .tags-card-inside-container-for-homepage {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            .tag-card-for-home-page {
                width: 45%;
                height: 460px;
            }
        }
    }
    .site-main-filtre-out-container {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0 auto 40px auto;

        .site-main-filter-single-search-container {
            width: 100%;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            .site-main-filtre {
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                .filter-search-button-container {
                    grid-area: 3 / 1 / 3 / 3;
                    grid-column: 1 / 4;
                    button.filtre-search-button {
                    }
                }
            }
        }
    }
    .filter-left-version {
        width: 100%;
        margin: 10px auto 0 auto !important;
        padding: 0;
        .filter-left-version-inside-container {
            .search-box {
                width: 67%;
                padding: 5px;
                margin: 0 !important;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .clear-filters-container {
                position: absolute;
                top: 20px;
                right: 20px;

                .clear-filters {
                    padding: 20px;
                    border-left: 2px solid white;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
            .pets-button-in-filter-container {
                width: 100%;
                // gap: 100px;

                .pets-button-container-for-left-version {
                    width: 100%;

                    .pets-button {
                        padding: 25px;

                        svg {
                            display: flex;
                        }
                    }
                }
            }
            .site-main-filtre {
                display: grid !important;
                grid-template-columns: repeat(3, 1fr) !important;
                gap: 10px;
                padding: 0px;

                .wrapper {
                    .select-button {
                        padding: 15px;
                        background: var(--bodyback-grey);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;

                        &:hover {
                            border-color: transparent !important;
                        }

                        div {
                            max-width: 170px !important;
                        }
                    }

                    .select-menu-dropdown {
                        position: absolute;
                        z-index: 1;
                        display: none;
                        background: white;
                        box-shadow: var(--shadow);
                    }
                    .select-button.active {
                        .select-menu-dropdown {
                            display: block !important;
                        }
                    }
                }

                .filter-price {
                    input[type="text"] {
                        padding: 15px;
                        background: var(--bodyback-grey);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;

                        &:hover {
                            border-color: transparent;
                        }
                    }
                }
                button.filtre-search-button {
                    grid-column: 2/4;
                }
            }
        }
    }

    .main-container {
        width: calc(100% - 50px);
    }
    .card-2-container {
        width: 100% !important;
        .inside-container {
            width: 100% !important;
            padding: 0;
            .card-2 {
            }
        }
    }
    .card-4-container {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 30px;
        padding: 10px 20px !important;
    }

    .order-collars-container {
        width: 100% !important;
        padding: 0 !important;
        .collar-order-modal-container {
            flex-direction: column;
            width: 100% !important;
            .slideshow-and-imglist-and-description {
                width: 100% !important;
                max-width: 95% !important;
                margin: auto;

                .slideshow-and-imglist {
                    width: 100% !important;
                    height: 550px !important;

                    .collar-manual-slideshow-container {
                        img {
                            width: 80% !important;
                            height: 80% !important;
                            object-fit: contain !important;
                        }

                        .imglist {
                            gap: 10px;

                            .img-box {
                                width: 90px;
                                height: 90px;
                                min-width: 90px;
                                min-height: 90px;
                                max-width: 90px;
                                max-height: 90px;

                                img {
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }
                        }
                    }
                }
                .collar-description-block-container {
                    width: 100% !important;
                    max-width: 80%;
                    padding: 0 !important;
                    margin: 40px auto;
                }
            }
            .collar-header-and-options-container {
                width: 80% !important;
                max-width: 80%;
                row-gap: 15px !important;
                padding: 0;
                margin: 0 auto;
                // background: var(--bodyback);

                .separate-container {
                    padding: 50px 100px !important;
                    // background: transparent !important;
                }
                .collar-header {
                    justify-content: center !important;
                }

                p.qr-collar-price {
                    justify-content: center !important;
                    font-size: 1.5rem !important;
                }

                .qr-without-collar {
                    flex-wrap: wrap;
                }
                .qr-tag-size {
                    gap: 15px !important;

                    button {
                        cursor: pointer;
                        // background: var(--bodyback-grey);
                        border-color: lightgrey;
                    }
                }
                form {
                    .input-container {
                        // row-gap: 15px !important;
                        input {
                            width: 100% !important;
                            //

                            // padding: 20px 30px !important;
                        }

                        .pets-info-for-qr {
                            .pet-photo-container {
                                margin: 15px auto 5px auto !important;
                            }
                        }
                    }
                    .separate-container {
                        .qr-collar-rules {
                            input[type="checkbox"] {
                                padding: 7px !important;
                            }
                        }
                    }
                }
                .wrapper {
                    width: 100% !important;
                    .select-button {
                        // padding: 20px 30px !important;
                    }
                }
            }
        }
    }

    .post-page-main-container {
        // width: 90% !important;

        .post-page-header {
            padding: 7px 0 !important;
        }

        .modal-for-img {
            .full-img-box {
                .close-imgbox {
                    top: 40px;
                    right: 20px !important;
                }
                img.modal-img {
                    width: auto;
                    height: 70% !important;
                }
            }
        }
        .city-view-id-container {
            gap: 14px !important;
        }
        .post-img-info-container {
            flex-direction: column;
            width: 100% !important;
            padding: 0 !important;
            .post-page-img-gallery {
                .slideshow-container {
                    // border-radius: 0 !important;
                    // -webkit-border-radius: 0 !important;
                    // -moz-border-radius: 0 !important;
                    // -ms-border-radius: 0 !important;
                    // -o-border-radius: 0 !important;
                    img {
                        // border-radius: 0 !important;
                        // -webkit-border-radius: 0 !important;
                        // -moz-border-radius: 0 !important;
                        // -ms-border-radius: 0 !important;
                        // -o-border-radius: 0 !important;
                    }

                    .post-status-container {
                        left: 30px !important;
                    }

                    .header-svg-container {
                        right: 35px !important;
                    }
                }
            }
            .right-container-for-banner-in-single-post {
                row-gap: 7px !important;
                width: 100% !important;
                max-width: 100% !important;
                margin: auto;
                padding: 0 !important;

                img {
                    width: calc(100vw - 70px) !important;
                    height: 160px !important;
                    max-height: 160px !important;
                    object-fit: cover;
                    // border-radius: 0 !important;
                    // -webkit-border-radius: 0 !important;
                    // -moz-border-radius: 0 !important;
                    // -ms-border-radius: 0 !important;
                    // -o-border-radius: 0 !important;
                }
            }
        }
        .post-img-and-info-container {
            width: 100% !important;
            max-width: 100% !important;
            .seller-info-container-static {
                width: 100% !important;
                // border-radius: 0 !important;
                // -webkit-border-radius: 0 !important;
                // -moz-border-radius: 0 !important;
                // -ms-border-radius: 0 !important;
                // -o-border-radius: 0 !important;

                .founded-singlepost,
                .missing-singlepost,
                .adopt-singlepost {
                    // border-radius: 14px !important;
                    // -webkit-border-radius: 14px !important;
                    // -moz-border-radius: 14px !important;
                    // -ms-border-radius: 14px !important;
                    // -o-border-radius: 14px !important;
                }
            }
            .post-description-and-info-main-container {
                .post-description-container {
                    // border-radius: 0 !important;
                    // -webkit-border-radius: 0 !important;
                    // -moz-border-radius: 0 !important;
                    // -ms-border-radius: 0 !important;
                    // -o-border-radius: 0 !important;
                }
                .sticky-banner-container2 {
                    width: 100% !important;
                    margin: 15px auto !important;
                }
                .post-pet-info-container {
                    // border-radius: 0 !important;
                    // -webkit-border-radius: 0 !important;
                    // -moz-border-radius: 0 !important;
                    // -ms-border-radius: 0 !important;
                    // -o-border-radius: 0 !important;
                }
            }
        }
    }
    .userpanel-container {
        gap: 10px !important;
        margin: 20px auto !important;
        .userpanel-left-container {
            // display: none;
        }
        .userpanel-screen-main-container {
            width: 100%;

            .personal-info-container {
                form.user-information {
                    flex-direction: column !important;
                    align-items: center !important;
                    gap: 50px;

                    .screen-user-photo {
                        width: 80%;
                        .photo {
                            img {
                            }
                        }
                        .upload-user-photo-btn {
                            width: fit-content;
                            background: var(--bodyback-grey) !important;
                            border-radius: 14px;
                            -webkit-border-radius: 14px;
                            -moz-border-radius: 14px;
                            -ms-border-radius: 14px;
                            -o-border-radius: 14px;
                        }
                    }
                    .user-information-inside-container {
                        justify-content: center;
                        gap: 20px;

                        .user-update-fields {
                            width: 100% !important;

                            input {
                                width: 100%;
                                padding: 20px;
                            }
                            select {
                                width: 100%;
                                padding: 20px;
                            }
                        }

                        input.submit {
                            width: 100%;
                            padding: 20px;
                        }
                    }
                }
                form.change-password {
                    flex-direction: column;
                    width: 100%;
                    padding: 20px;

                    .user-update-fields {
                        width: 100% !important;

                        input[type="password"] {
                            width: 100%;
                            padding: 20px;
                        }
                    }

                    input[type="submit"] {
                        width: 100%;
                        padding: 20px;
                    }
                }
            } // END PERSONAL INFO CONTAINER

            .active-post-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                width: 100% !important;
                padding: 0 !important;
                .myactive-post-lit-cont {
                    flex-direction: column !important;
                    gap: 7px;
                    max-height: 100% !important;
                    // overflow: hidden;
                    div.act-post-img {
                        width: 100% !important;
                        min-width: 100%;
                        min-height: 200px !important;
                        max-height: 200px !important;
                    }
                    .myactive-post-info-container {
                        padding: 0 4px;
                        .myactive-post-info-inside-container {
                            gap: 15px;
                            border: 1px solid transparent;
                            ul {
                                // flex-direction: column;
                                justify-content: space-between !important;
                                align-items: flex-start;
                                flex-wrap: wrap;
                                width: fit-content !important;
                                max-width: 100% !important;
                                gap: 14px !important;
                                li {
                                    font-size: 0.9rem !important;
                                    span {
                                        font-size: 0.9rem;
                                    }
                                }
                                span {
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }
                    .vip-edit-delete-container {
                        width: 100% !important;
                        height: auto !important;
                        padding-right: 0 !important;
                        background: var(--bodyback-grey);
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                        .edit-delete {
                            flex-direction: row !important;
                            justify-content: center !important;
                            gap: 30px !important;
                            opacity: 1 !important;
                        }
                    }
                }
            }
            .generate-qr-container {
                width: 100%;
                height: 100%;
                .purchased-collars {
                    .purchased-collars-cards-container {
                        grid-template-columns: repeat(1, 1fr) !important;
                        gap: 10px;
                        // padding: 0 100px;

                        .purchased-collars-card {
                            // max-width: 550px;
                            margin: auto !important;

                            .pet-img-and-buttons {
                                // align-items: center;

                                .img-box {
                                    // width: 135px;
                                    // height: 135px;
                                    // min-width: 135px;
                                    // min-height: 135px !important;
                                }
                            }
                        }
                    }
                }
            }

            .favorite-post-container {
                .favorite-post-inside-container {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;
                }
            }
        }
        // .active-post-container {
        //     width: 100% !important;
        //     padding: 0 10px !important;
        //     .myactive-post-lit-cont {
        //         .myactive-post-info-container {
        //             .myactive-post-info-inside-container {
        //                 ul {
        //                     width: 155px !important;
        //                     max-width: 180px !important;
        //                 }
        //             }
        //         }
        //         .edit-delete {
        //             opacity: 1 !important;
        //         }
        //     }
        // }
    }
    .rules-parent-container {
        margin: 40px auto;

        .rules-inside-container {
            .table-of-content {
                display: none;
            }
            .editor {
                width: 100%;
                padding: 25px;
            }
        }
    }
    .login-signup-parent-modal-container {
        width: 70%;
        // height: 100vh;
        margin: 0 auto !important;
        padding: 0 !important;
        .login-advertise-container {
            display: none;
        }
    }
    .login-container {
        width: 100%;
        padding: 40px;
    }
    .registration-container {
        width: 100%;
        padding: 40px;
    }
    .recover-password {
        margin-left: 20px;
    }
    .footer-parent-container {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        .footer-parent-inside-container {
            // padding: 0 20px;
        }
    }
    .footer {
        flex-wrap: wrap;
    }
    .copyright {
        // padding: 14px 20px !important;
    }
    .qr-collar-instruction-container {
        margin: 0 auto !important;
    }
    .qr-collar-order-container {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 40px !important;
    }
    .video-instruction {
        width: 100%;
        height: 400px !important;
        max-height: auto !important;
    }
    .find-pet-info-container-blocked {
        .find-pet-info-container-blocked-text {
            p {
                flex-direction: column;
                span {
                }
            }
        }
        .find-pet-info-language-switcher-for-desktop {
            display: flex !important;
            gap: 30px !important;
            margin-top: 13px !important;
        }
    }
    .find-pet-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
        width: 100%;
        height: 100%;
        // max-width: 767px;
        margin: 0 auto !important;
        padding: 0;
        padding-bottom: 20px;
        background: var(--background) !important;
        // background-image: url("../../../public/img/pawprint2.jpg") !important;
        // background-repeat: no-repeat !important;
        // background-position: top 50% !important;
        // background-size: contain !important;
        .find-pet-info-container-header {
            display: none;
            justify-content: center;
            align-items: center;
            z-index: 1;
            width: 100%;
            padding: 7px 0;
            //background: $sec-design-color;
            background: var(--background);
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-bottom-left-radius: $secondary-border-radius;
            border-bottom-right-radius: $secondary-border-radius;
            box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
            .logo-box {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;
                .logo-img-box {
                    display: none; // flex;
                    justify-content: flex-start;
                    align-items: center;
                    // background: white;
                    // padding: 5px;
                    // border-radius: 50%;
                    // -webkit-border-radius: 50%;
                    // -moz-border-radius: 50%;
                    // -ms-border-radius: 50%;
                    // -o-border-radius: 50%;
                }
                a {
                    display: none;
                    font-family: "Shantell Sans", cursive;
                    font-size: 1.8rem;
                    font-weight: bolder;
                    color: $sec-design-color;
                    text-decoration: none;
                    letter-spacing: 2.5px;
                    padding: 0;
                    span {
                        font-size: 1.8rem;
                        font-weight: bolder;
                        color: $sec-design-color;
                    }
                }
            }
        }
        .find-pet-info-container-body {
            flex-direction: column;
            // gap: 30px !important;
            width: 100% !important;
            height: auto !important;
            // margin-top: -15px;
            background: transparent !important;
            .pet-photo-and-name-container {
                position: relative;
                width: 100%;
                height: auto !important;
                padding: 0;
                background: transparent !important;
                // border: 1px solid green;
                img {
                    width: 100%;
                    height: 100%;
                    min-width: 100%;
                    min-height: 750px;
                    max-width: 100%;
                    max-height: 750px;
                    margin: 0 auto !important;
                    padding: 0 !important;
                    object-fit: cover;
                    border-radius: 0;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    -ms-border-radius: 0;
                    -o-border-radius: 0;
                    // border: 10px solid $sec-design-color;
                    // box-shadow: var(--shadow);
                    box-shadow: none !important;
                }
                .pet-name {
                    position: absolute;
                    bottom: 70px;
                    left: 50%;
                    width: fit-content;
                    max-width: 80%;
                    color: white;
                    text-align: center !important;
                    font-size: 1.9rem;
                    font-weight: 600;
                    padding: 4px 20px;
                    margin: 0 auto;
                    background: rgba(55, 55, 55, 1);
                    //margin-top: -80px;
                    border-radius: $third-border-radius;
                    -webkit-border-radius: $third-border-radius;
                    -moz-border-radius: $third-border-radius;
                    -ms-border-radius: $third-border-radius;
                    -o-border-radius: $third-border-radius;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                }
            }
            .logo-button-container {
                position: relative;
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: -30px;
                background: var(--background);
                box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45);
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                .logo-button {
                    // position: absolute;
                    // top: -32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 7px;
                    margin-top: -32px;
                    background: var(--background);
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                    }
                }
                .site-link {
                    font-family: "Shantell Sans", cursive;
                    font-size: 1.8rem;
                    font-weight: bolder;
                    color: $sec-design-color;
                    text-decoration: none;
                    letter-spacing: 2.5px;
                    padding: 0;
                }
            }
            .find-pet-info-outside-container {
                flex-direction: row;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;
                padding: 0;
                .pet-type {
                    display: block;
                }
                .about-pet {
                    width: 100% !important;
                }
                .find-pet-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 14px;
                    width: 48%;
                    padding: 10px 52px;
                    background: var(--background);
                    border-radius: 0; // 23px:;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    -ms-border-radius: 0;
                    -o-border-radius: 0;
                    .find-pet-info-header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        // flex-direction: row-reverse;
                        gap: 0;
                        width: 100%;
                        // color: white;
                        // font-size: 2vh;
                        // font-weight: 500;
                        // padding: 1.5vh;
                        // background: $sec-design-color;
                        // border-radius: $third-border-radius;
                        // -webkit-border-radius: $third-border-radius;
                        // -moz-border-radius: $third-border-radius;
                        // -ms-border-radius: $third-border-radius;
                        // -o-border-radius: $third-border-radius;
                        .header-svg {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            background: $sec-design-color;
                            padding: 8px;
                            border-radius: 50%;
                            -webkit-border-radius: 50%;
                            -moz-border-radius: 50%;
                            -ms-border-radius: 50%;
                            -o-border-radius: 50%;
                            // border-bottom-left-radius: $secondary-border-radius;
                            // border-bottom-right-radius: $secondary-border-radius;
                            svg {
                                width: 19px;
                                height: 19px;
                                path {
                                    fill: white;
                                    stroke: white;
                                }
                            }
                        }
                        .header-text {
                            position: relative;
                            // width: 100%;
                            // color: white;
                            font-size: 1rem;
                            font-weight: 600;
                            padding: 8.5px 14px;
                            // background: $sec-design-color;
                            // border-radius: $third-border-radius;
                            // -webkit-border-radius: $third-border-radius;
                            // -moz-border-radius: $third-border-radius;
                            // -ms-border-radius: $third-border-radius;
                            // -o-border-radius: $third-border-radius;
                            &:after {
                                display: none;
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 30px;
                                height: 100%;
                                background: $primary-bg;
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                            }
                        }
                        .heart {
                            color: red;
                        }
                    }
                    .label-container {
                        width: 100%;
                        padding: 0 3px;
                        span {
                            color: grey;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                        p {
                            width: 100%;
                            // color: white;
                            font-size: 1rem;
                            font-weight: 500;
                            padding: 7px 0; // 7px 14px;
                            // margin-left: 1vh;
                            // background: rgba(55, 55, 55, 1);
                            border-bottom: 2px solid $hover-color;
                            // border-radius: $secondary-border-radius;
                            // -webkit-border-radius: $secondary-border-radius;
                            // -moz-border-radius: $secondary-border-radius;
                            // -ms-border-radius: $secondary-border-radius;
                            // -o-border-radius: $secondary-border-radius;
                        }
                    }
                    textarea {
                        width: 100%;
                        height: 90px;
                        max-height: 90px;
                        // color: white;
                        font-size: 1rem;
                        font-weight: 500;
                        padding: 0 3px;
                        border: none;
                        // margin-left: 1vh;
                        // background: rgba(55, 55, 55, 1);
                        // border-radius: 30px;
                        // -webkit-border-radius: 30px;
                        // -moz-border-radius: 30px;
                        // -ms-border-radius: 30px;
                        // -o-border-radius: 30px;
                    }
                    // .vaccinated {
                    //   color: white;
                    //   background: #26b753;
                    // }
                    // .not-vaccinated {
                    //   color: white;
                    //   background: red;
                    // }
                }
            }
        }
        .find-pet-info-call-owner {
            position: relative;
            z-index: 1;
            display: flex !important;
            //flex-direction: column;
            justify-content: space-between !important;
            align-items: center;
            gap: 2.5vh;
            width: 90% !important;
            padding: 10px 13px !important;
            margin: 15px 0 !important;
            //margin-bottom: 0 !important;
            background: rgba(55, 55, 55, 1);
            border-radius: $third-border-radius;
            -webkit-border-radius: $third-border-radius;
            -moz-border-radius: $third-border-radius;
            -ms-border-radius: $third-border-radius;
            -o-border-radius: $third-border-radius;
            button,
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2vh;
                width: fit-content;
                font-size: 2vh;
                text-decoration: none;
                cursor: pointer;
                padding: 14px;
                border: none;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                svg {
                    width: 27px;
                    height: 27px;
                    path {
                        stroke-width: 0.8 !important;
                        stroke: rgba(55, 55, 55, 1) !important;
                        fill: rgba(55, 55, 55, 1);
                        fill-opacity: 0.24;
                    }
                }
            }
            .call-owner {
                color: white;
                background: var(--background) !important;
                border-color: transparent !important;
                svg path {
                    stroke-width: 0.8 !important;
                    stroke: #26b753 !important;
                    fill: #26b753;
                    fill-opacity: 0.75;
                }
            }
        }
        .find-pet-info-call-owner-for-desktop {
            display: none !important;
        }
    }
    .find-pet-info-language-switcher-for-desktop {
        display: none !important;
    }
    .find-pet-info-language-switcher {
        position: fixed;
        top: 350px;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 14px;
        width: fit-content;
        padding: 3.5px 5px;
        background: var(--bodyback-grey);
        border-top-left-radius: $primary-border-radius;
        border-bottom-left-radius: $primary-border-radius;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        box-shadow: var(--shadow);
        &:hover {
            right: 0;
        }
        img {
            width: 30px;
            // height: 21px;
            // border-radius: inherit;
            // -webkit-border-radius: inherit;
            // -moz-border-radius: inherit;
            // -ms-border-radius: inherit;
            // -o-border-radius: inherit;
        }
    }
}
